import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import UpdateStatusPopup from "../components/updateStatus";
import Navbar from "../components/navbar/navbar";
import "./adminPanel.css";

export const NewOrders = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "orderDetails"));
        const newData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((item) => item.payment_status && !item.delivery_status);
        setData(newData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleUpdateStatus = async (status) => {
    console.log(status);
    const docRef = doc(db, "orderDetails", selectedItem.id);
    await updateDoc(docRef, {
      delivery_status: status,
    });
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const handleOpenPopup = (item) => {
    setSelectedItem(item);
    console.log(item);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setSelectedItem(null);
    setIsPopupOpen(false);
  };
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Pad single digits with leading zeros
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  return (
    <div className="logs">
      <header>
        <Navbar />
      </header>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 2rem",
          }}
        >
          <h2>Order Status</h2>
          <h2>Delivery Count: {data.length}</h2>
        </div>
        <table>
          <thead>
            <tr>
              <th>Track Id</th>
              <th>Customer Id</th>
              <th>Name</th>
              <th>Email-Id</th>
              <th>PhoneNumber</th>
              <th>Address</th>
              <th>Product_Code</th>
              <th>Selected Size</th>
              <th>Payment_Status</th>
              <th>Cod_Status</th>
              <th>Delivery_Status</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.tract_id}</td>
                <td>{item.id}</td>
                <td>{item.billing_name}</td>
                <td>{item.billing_email}</td>
                <td>{item.billing_zip}</td>
                <td>
                  {item.apartmentNumber},{item.billing_address},{" "}
                  {item.billing_zip}, {item.billing_city}, {item.billing_state},{" "}
                  {item.billing_country}
                </td>
                <td>
                  {item.product_code.map((code, index) => (
                    <div key={index}>{code}</div>
                  ))}
                </td>
                <td>
                  {item.selectedSize ? (
                    item.selectedSize.map((size, index) => (
                      <div key={index}>{size}</div>
                    ))
                  ) : (
                    <div>-</div>
                  )}
                </td>
                <td>{item.payment_status ? "Paid" : "Not Paid"}</td>
                <td>{item.cod_status ? "True" : "False"}</td>
                <td>
                  {item.delivery_status ? "Delivered" : "Not Delivered"}
                  <button
                    className="updateStatus"
                    onClick={() => handleOpenPopup(item)}
                  >
                    Update Status
                  </button>
                </td>
                <td>{formatTimestamp(item.time_stamp)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <UpdateStatusPopup
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          onUpdate={handleUpdateStatus}
        />
      </div>
    </div>
  );
};
