    import React, { useState, useEffect } from 'react';
    import Popup from 'reactjs-popup';
    import 'reactjs-popup/dist/index.css';
import "./sizeChart.css"
    import close from "./Vector.png"

    const Popup1 = ({ handleClosePopup, isPopupVisible2 }) => {

    return (
        <section >
        <div >
        
            <Popup open={isPopupVisible2}   position="top left">
                <div className='desktopContainer'>
                    <img src={close} className='desktopClose' onClick={handleClosePopup} alt=""/>
                    <p className='desktopSizeText'>SIZE CHART</p>
                    <p className='desktopSizeSubText'>Our sizes are engineered for Indian men. Every fit has been iterated & perfected over years of testing,<br></br> resulting in two base fits: Slim and Regular.</p>
                    <div className='desktopSubContainer'>
                        <table className=' !mt-[12px] '>
                            <tr className='desktopTableRow '>
                                <th className=' desktopTableHead'></th>
                                <th className='desktopTableHead'>MEDIUM</th>
                                <th className='desktopTableHead'>LARGE</th>
                                <th className='desktopTableHead'>X-LARGE</th>
                                <th className='desktopTableHead'>XX-LARGE</th>
                                <th className='desktopTableHead'>3X-LARGE</th>
                            </tr>
                            <tr className='desktopTableRow'>
                            <th className='desktopTableHead'>SHOULDER</th>
                                <td className='desktopTableData'><p className='desktopTableText'>18</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>18</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>19.5</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>21</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>22</p></td>

                            </tr>
                            <tr className='desktopTableRow '>
                            <th className='desktopTableHead'>CHEST</th>
                                <td className='desktopTableData'><p className='desktopTableText'>40</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>42</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>44</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>46</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>50</p></td>

                            </tr>
                            <tr className='desktopTableRow '>
                            <th className='desktopTableHead'>FRONT LENGTH</th>

                                <td className='desktopTableData'><p className='desktopTableText'>28</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>28.5</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>30</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>31.5</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>31.5</p></td>

                            </tr>
                            <tr className='desktopTableRow'>
                            <th className='desktopTableHead'>SLEEVE LENGTH</th>
                                <td className='desktopTableData'><p className='desktopTableText'>8</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>9</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>9</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>9.5</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>9.5</p></td>
                            </tr>
                            <tr className='desktopTableRow'>
                            <th className='desktopTableHead'>STOMACH</th>
                                <td className='desktopTableData'><p className='desktopTableText'>40</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>42</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>44</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>48</p></td>
                                <td className='desktopTableData'><p className='desktopTableText'>52</p></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className='mobileContainer'>
                <img src={close} className='mobileClose' onClick={handleClosePopup} alt=""/>
                <p className='mobileSizeText'>SIZE CHART</p>
                <p className='mobileSubText'>Our sizes are engineered for Indian men. Every fit has been iterated & perfected over years of testing, resulting in two base fits: Slim and Regular.</p>
                <div className='mobileSubContainer'>
                        <table className=' mobileTable'>
                            <tr className='mobilethtr'>
                                <th className='mobileth'></th>
                                <th className='mobileth'>MEDIUM</th>
                                <th className='mobileth'>LARGE</th>
                                <th className='mobileth'>X-LARGE</th>
                                <th className='mobileth'>XX-LARGE</th>
                                <th className='mobileth'>3X-LARGE</th>
                            </tr>
                            <tr className='mobilethtr '>
                            <th className='mobileth'>SHOULDER</th>
                                <td className='mobiletrtd'><p className='desktopTableText'>18</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>18</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>19.5</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>21</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>22</p></td>

                            </tr>
                            <tr className='mobilethtr '>
                            <th className='mobileth'>CHEST</th>
                                <td className='mobiletrtd'><p className='desktopTableText'>40</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>42</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>44</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>46</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>50</p></td>

                            </tr>
                            <tr className='mobilethtr  '>
                            <th className='mobileth'>FRONT LENGTH</th>

                                <td className='mobiletrtd'><p className='desktopTableText'>28</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>28.5</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>30</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>31.5</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>31.5</p></td>

                            </tr>
                            <tr className='mobilethtr'>
                            <th className='mobileth'>SLEEVE LENGTH</th>
                                <td className='mobiletrtd'><p className='desktopTableText'>8</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>9</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>9</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>9.5</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>9.5</p></td>
                            </tr>
                            <tr className='mobilethtr '>
                            <th className='mobileth'>STOMACH</th>
                                <td className='mobiletrtd'><p className='desktopTableText'>40</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>42</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>44</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>48</p></td>
                                <td className='mobiletrtd'><p className='desktopTableText'>52</p></td>
                            </tr>
                        </table>
                    </div>
                </div>
        </Popup>

        </div>
        </section>

    );
    };

    export default Popup1;

    // <a className="close" onClick={closePopup}>
    // &times;
    // </a>