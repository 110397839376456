export const menuItems = [
  {
    title: "ADD PRODUCT",
    link: "/addProduct",
  },
  {
    title: "ORDER DETAILS",
    link: "/newOrders",
  },
  {
    title: "LOGS",
    link: "/OrderLogs-fU9H4N4AvaZw3mmZy0IePDu4x222",
  },
  {
    title: "COD Orders",
    link: "/codOrders",
  },
  {
    title: "DELIVERY STATUS",
    link: "/deliveryStatus",
  },
  {
    title: "USERS",
    link: "/usersDetails",
  },
  {
    title: "ACTIVITIES",
    link: "/activities",
  },
];
