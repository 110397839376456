import React from 'react';
import { baseURL } from '../api';

const Formcc = () => {
  return (
    <form method='POST' action='https://diosdamroos-backend.onrender.com/ccavRequestHandler'>
      <table>
        <tbody>
          <tr>
            <td colSpan="2">Compulsory information</td>
          </tr>
          <tr>
            <td>Merchant Id</td>
            <td><input type="text" name="merchant_id" id="merchant_id" value="2674019" /></td>
          </tr>
          <tr>
            <td>Order Id</td>
            <td><input type="text" name="order_id" value="69" /></td>
          </tr>
          <tr>
            <td>Currency</td>
            <td><input type="text" name="currency" value="INR" /></td>
          </tr>
          <tr>
            <td>Amount</td>
            <td><input type="text" name="amount" value="100" /></td>
          </tr>
          <tr>
            <td>Redirect URL</td>
            <td><input type="text" name="redirect_url" value="http://diosdamroos.com/success" /></td>
          </tr>
          <tr>
            <td>Cancel URL</td>
            <td><input type="text" name="cancel_url" value="http://diosdamroos.com/" /></td>
          </tr>
          <tr>
            <td>Language</td>
            <td><input type="text" name="language" id="language" value="EN" /></td>
          </tr>
          <tr>
      <td></td>
      <td><input type="submit" value="Checkout"/></td>
    </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Formcc;
