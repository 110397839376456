import React, { useEffect, useState } from "react";
import './cart.css';

// import { collection, getDocs } from 'firebase/firestore';
import { auth } from '../firebase';
import firebase from 'firebase/compat/app';
import { RiDeleteBinLine } from 'react-icons/ri';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { cart } from "react-icons-kit/entypo/cart";
import del from '../Components/images/delete.png'
import image from '../Components/images/popup-image.jpg';
import { useAuth0 } from "@auth0/auth0-react";



const Cart = ({ onTotal }) => {
    const { isAuthenticated, user } = useAuth0();
    
const firebaseConfig = {
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const uid = GetUserID();
const [cartItems, setCartItems] = useState([]);
const [totalPrice, setTotalPrice] = useState(0);
const [selectedQty, setSelectedQty] = useState(1);
const [productCodes, setProductCodes] = useState([])
const [selectedSize,setSelectedSize] = useState([])

const db = firebase.firestore()
function handleDeleteEntry(skuCode) {
    // Check if skuCode is available
    if (!isAuthenticated) {
        console.error('SKU code is not available.');
        return;
    }
    
    // Create a query to find the document with the matching SKU code
    const cartRef = collection(db, `cart ${user.sub}`);
    const q = query(cartRef, where('id', '==', skuCode));
    
    // Execute the query to get the matching document(s)
    getDocs(q)
        .then((querySnapshot) => {
        if (!querySnapshot.empty) {
            // If a document with the matching SKU code is found, delete it
            querySnapshot.forEach((doc) => {
            deleteDoc(doc.ref)
                .then(() => {
                console.log('Entry successfully deleted!');
                
                })
                .catch((error) => {
                console.error('Error deleting entry:', error);
                });
            });
        } else {
            console.log('No document found with the given SKU code.');
        }
        })
        .catch((error) => {
        console.error('Error fetching documents:', error);
        });
    }


useEffect(() => {
    const fetchCartItems = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, `cart ${user.sub}`));
            const items = querySnapshot.docs.map((doc) => doc.data());
            setCartItems(items);
        } catch (error) {
            console.error('Error fetching cart items:', error);
        }
    };
    fetchCartItems();
}, );


useEffect(() => {
    let calculatedPrice = 0;

    cartItems.forEach((item) => {
        const price = item.price || 0;
        calculatedPrice += price;
        // console.log(item.price)
    });
    setTotalPrice(calculatedPrice);
    // onTotal(calculatedPrice);
}, [cartItems]);
useEffect(() => {
    let productCodes = [];

    cartItems.forEach((item) => {
        const productCode = item.product_code ;
        productCodes.push(productCode);
        // console.log(item.price)
    });
    setProductCodes(productCodes);
    // onTotal(calculatedPrice);
}, [cartItems]);

useEffect(() => {
    let selectedSizes = [];

    cartItems.forEach((item) => {
        const productCode = item.selectedSize ;
        selectedSizes.push(productCode);
        // console.log(item.price)
    });
    setSelectedSize(selectedSizes);
    // onTotal(calculatedPrice);
}, [cartItems]);
function GetUserID() {
    const [userID, setUID] = useState(null);
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUID(user.uid);
            }
        });
    }, []);
    return userID;
}

const handleQtySelect = (e, id) => {
    const newQty = parseInt(e.target.value, 10);
    setSelectedQty(newQty);
    // // Replace 'your_collection_name' with the actual name of your collection
    db.collection(`cart ${uid}`)
      .where('id', '==', id) // Replace 'your_given_string' with the desired ID
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          // Assuming the ID is unique, there will be only one document in the result
          const docSnapshot = querySnapshot.docs[0];
          const docRef = docSnapshot.ref;
          const tempPrice = docSnapshot.data().totalProductPrice
          docRef.update({finalPrice: tempPrice*newQty})
          docRef.update({ qty: newQty })
          setTimeout(function() {
            window.location.reload()
          }, 100);
          
          
          
        } else {
          console.log('Document not found with the specified ID');
        }
      })
      .then(() => {
        console.log('Quantity updated successfully!');
      })
      .catch((error) => {
        console.error('Error updating quantity:', error);
      });
    
  };
//   const navigate = useNavigate()
// const [product, setProduct] = useState(null);

  useEffect(() => {
    // Reference to your Firestore document
    const docRef = firebase.firestore().collection(`cart ${uid}`).doc('your_document_id');

    // Fetch the data from Firestore
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // If the document exists, update the selectedQty state with the fetched value
          setSelectedQty(doc.data().qty);
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);

  const navigate = useNavigate()
  function handlePTP(e) {
    
    navigate('/userForm',{state:{e,productCodes,cartItems,selectedSize}})
  }

return (
    <div style={{backgroundColor:"#F4EFEC", flexDirection:'row', display:'flex' }}>
        
    
    <div className="parent-container-cart" >
        <h1 className="cartText-cart">Shopping Cart</h1>
        <div style={{backgroundColor:"#F4EFEC"}}>
        {cartItems.length === 0 ? ( // Check if cartItems array is empty
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection:'column', height:'80vh'}}>
                <h1 >Your Cart Is Empty</h1>
                <Link to="/shop">Shop With Us</Link>
            </div>
    ) : (
        <div style={{ backgroundColor: "#F4EFEC" }}>
            {cartItems.map((item) => (
                <div className="mainTable-cart" key={item.id}>
                    <div className="column-container-cart">
                        <div className="row-container-cart" style={{marginTop:"50px"}} >
                            <img src={item.img1} className="productImage-cart" alt="Product" />
                            <div className="innerCol-container-cart">
                                <h1 className="bigTitty-cart">{item.small_title}</h1>
                                <span className="smallTitty-cart" style={{textAlign: 'center'}}>{item.big_title}</span>
                                <h3 nclassName="smallTitty-cart">₹{item.price.toLocaleString()}</h3>
                                <h3 className="smallTitty-cart"><b>Selected size: </b>{item.selectedSize}</h3>
                                <div className="row-container-cart editProduct-cart">
                                
                                 
                                  <img src={del} onClick={()=>handleDeleteEntry(item.id)} alt="" height={30} className="trashIcon-cart"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ))}
        </div>
    )}
              
        </div>
        
    <div className="sticky-bottom-cart">
        <p className="stickyText-cart">Total Price: ₹{totalPrice.toLocaleString()}</p>
        <button className="chkOut-cart" onClick={()=>handlePTP(totalPrice)}>Proceed To Pay</button>
    </div>
    </div>
   
    </div>
);
}
// onChange={(e) => handleQuantityChange(e, item.sku_code)}

// <select value={item.qty} onChange={(e)=>handleQtySelect(e, item.id)}>
//                                 {/* Loop to generate options from 1 to 10 */}
//                                 {[...Array(10)].map((_, index) => (
//                                   <option key={index + 1} value={index + 1}>
//                                     {index + 1}
//                                   </option>
//                                 ))}
//                               </select>
// <span className="smallTitty-cart">Quantity:</span>
export default Cart;
