import React from "react";
import "./FooterComps.css";

const Returnsandrefunds = () => {
  const whatsapp = () => {
    window.open("https://wa.me/919324283876", "_blank");
  };
  return (
    <div className="Main-FC">
      <p
        style={{
          color: "#82480C",
          paddingLeft: "0rem",
          fontSize: "0.9rem",
          fontFamily: "'IBM Plex Mono', monospace",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none", color: "#82480C" }}>
          Home{" "}
        </a>
        <span style={{ cursor: "default", color: "#82480C" }}>
          {"< Returns & Refunds"}{" "}
        </span>
      </p>
      <h1 className="Header-FC">REFUNDS AND RETURNS:</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We follow the highest quality control standards and ensure that each
        product is made with the care and workmanship that our customers expect.
        We reserve the right to cancel an order for which payment has already
        been received. This may occur if stock is insufficient or the quality of
        goods ordered does not meet our standards. Should we exercise this
        right, the customer will receive a full refund via the original payment
        method.
      </p>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        If you're unhappy with your purchase, we will find a solution for you.
        Dios products can be exchanged within 14 days for another size or
        product of the same or higher value. Dios products must be returned in
        good condition, with the abels intact. Please keep in mind that you are
        responsible for the safe delivery back to Dios of all returns, we
        suggest courier and tracking. We will help you with our logistic service
        for the same. Products once exchanged, will no longer be returnable.
      </p>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Please note, discounted items or custom orders with alterations cannot
        be returned or exchanged. At Dios, we do our best to provide the finest
        quality products. Like most natural textiles, our fabrics are not
        guaranteed against natural wear, abrasion, stretch, shrinkage, fading,
        or dye-lot variations. If and when you decide to return any of our
        articles, you will receive a full refund of the amount directly from us.
        However, please allow us 5-7 working days for the same.
      </p>
      <h1 className="SubHeader-FC">REVIEWS</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Any complaints or compliments regarding the standard and quality of the
        products bought by consumers should be directed to
        https://diosdamroos.com/review
        <br />
        <span className="Bullet-FC">&#10209;</span>If you are not satisfied with
        the quality of our articles after use, please contact us and you will be
        taken care of as always.
      </p>

      <h1 className="SubHeader-FC">INTERNATIONAL ORDERS</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        For international delivery, please get in touch with us at{" "}
        <a className="Highlight-FC" href="/FAQs" onClick={whatsapp}>
          +91 93242 83876{" "}
        </a>{" "}
        or{" "}
        <a
          className="Highlight-FC"
          href="https://mail.google.com/mail/u/0/#inbox"
          target="_blank"
          rel="noreferrer"
        >
          diosdamroos1988@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default Returnsandrefunds;
