import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './activities.css';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import { MdDelete } from 'react-icons/md';
import Navbar from '../components/navbar/navbar';

const Activity1Content = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'popUp'));
        const newData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setData(newData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (itemId) => {
    try {
      await deleteDoc(doc(db, 'popUp', itemId));
      // Refresh the data after deletion
      const updatedData = data.filter((item) => item.id !== itemId);
      setData(updatedData);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div>
      <h2>Popup</h2>
      <table>
        <thead>
          <tr>
            <th>Email-Id</th>
            <th>Heard us from</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.email}</td>
              <td>{item.Options}</td>
              <td>
                <MdDelete onClick={() => handleDelete(item.id)} size={21} style={{cursor:'pointer'}}></MdDelete>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Activity2Content = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'emailSubs'));
        const newData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setData(newData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (itemId) => {
    try {
      await deleteDoc(doc(db, 'emailSubs', itemId));
      // Refresh the data after deletion
      const updatedData = data.filter((item) => item.id !== itemId);
      setData(updatedData);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div>
      <h2>Emails subscribed</h2>
      <table>
        <thead>
          <tr>
            <th>Email-Id</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.email}</td>
              <td>
              <MdDelete onClick={() => handleDelete(item.id)} size={21} style={{cursor:'pointer'}}></MdDelete>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const Activity3Content = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClass = i <= rating ? 'star-filled' : 'star-empty';
      stars.push(<span key={i} style={{ color: "#82480C", fontSize: '24px' }} className={`star ${starClass}`}>&#9733;</span>);
    }
    return stars;
  };
  
  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'feedback'));
        const feedbackArray = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setFeedbackData(feedbackArray);
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      }
    };

    fetchFeedback();
  }, []);
  const handleDelete = async (itemId) => {
    try {
      await deleteDoc(doc(db, 'feedback', itemId));
      // Refresh the data after deletion
      const updatedData = feedbackData.filter((item) => item.id !== itemId);
      setFeedbackData(updatedData);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Feedbacks</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {feedbackData.map((feedback) => (
            <tr key={feedback.id}>
              <td>{feedback.userEmail}</td>
              <td>
                <p style={{width:"30rem"}}><span style={{fontWeight:"bold"}}>How was your overall shopping experience with us?: </span><br></br>{renderStars(feedback.Question1)}</p>
                <p><span style={{fontWeight:"bold"}}>How was the packaging of the article you received?: </span><br/>{renderStars(feedback.Question2)}</p>
                <p><span style={{fontWeight:"bold"}}>How did you like the finishing of the article you received?:</span><br/> {renderStars(feedback.Question3)}</p>
                <p><span style={{fontWeight:"bold"}}>How was your overall shopping experience with us?: </span><br/>{renderStars(feedback.Question4)}</p>
                <p><span style={{fontWeight:"bold"}}>Comment: </span>{feedback.comment}</p>
              </td>
              <td>
              <MdDelete onClick={() => handleDelete(feedback.id)} size={21} style={{cursor:'pointer'}}></MdDelete>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const Activities = () => {
  return (
    <div className='logs'>
    <header>
    <Navbar />
  </header>
    <Tabs>
      <TabList>
        <Tab>Popup</Tab>
        <Tab>Emails-Subscription</Tab>
        <Tab>FeedBacks</Tab>
      </TabList>

      <TabPanel>
        <Activity1Content />
      </TabPanel>

      <TabPanel>
        <Activity2Content />
      </TabPanel>
      <TabPanel>
        <Activity3Content />
      </TabPanel>
    </Tabs>
    </div>
  );
};

export default Activities;
