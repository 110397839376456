import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
  authDomain: "diosdamroo-b97a0.firebaseapp.com",
  projectId: "diosdamroo-b97a0",
  storageBucket: "diosdamroo-b97a0.appspot.com",
  messagingSenderId: "28288876745",
  appId: "1:28288876745:web:b52667cf7c41499a648d18",
  measurementId: "G-DFF1V6MBR0"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app);

export { app, db, auth, storage }