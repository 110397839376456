
export const menuItems = [
    {
        title: "SHOP",
        submenu: [ 
            {
                title: "ALL PRODUCT",
                link: "/shop?category=&color=&pattern=&fabric=&size=",
            },
            {
            title: "SHIRT",
            submenu: [{
                    title: "LINEN",
                    link: "/shop?category=Shirt&color=&pattern=&fabric=Linen&size=",
                },
                {
                    title: "COTTON",
                    link: "/shop?category=Shirt&color=&pattern=&fabric=Cotton&size=",
                },
            ],
        },
            
            {
                title: "PANT",
                link: "/shop?category=Pants&color=&pattern=&fabric=&size=",
            },
            {
                title: "CO-ORD",
                link: "/shop?category=Coord+Set&color=&pattern=&fabric=&size=",
            },
            // {
            //     title: "PYJAMA CO-ORD",
            //     link: "/shop?category=Pyjama+Coord&color=&pattern=&fabric=&size=",
            // },
            {
                title: "KURTAS",
                submenu: [{
                    title: "SHORT KURTA",
                    link: "/shop?category=Short+Kurta&color=&pattern=&fabric=&size=",
                },
                {
                    title: "LONG KURTA",
                    link: "/shop?category=Long+Kurta&color=&pattern=&fabric=&size=",
                },
            ],
            },
            {
                title: "T-SHIRT",
                link: "/shop?category=T-Shirt&color=&pattern=&fabric=&size=",
            },
            {
                title: "TROUSER",
                link: "/shop?category=Cotton+Trouser&color=&pattern=&fabric=&size=",
            },
        ],
    },
    // {
    //     title: "OUR RECOMMENDATION",
    //     link: "/our-recommendations",
    // },
    {
        title: "FLAGSHIP JOURNEY",
        link: "/flagshipStore",
    },
    {
        title: "PHILOSOPHY",
        link: "/philosophy",
    },
    
    ];