import React, { useEffect, useState } from "react";
import Carousel from "../carousel/carousel";
// import Banner from "../Body/Banner";
import Featuring from "../linen-recomendations/featuring";
import Popup from "../Components/PopUp";
import PopupSignIn from "../Components/popupSignin";

import "./Home.css";
import Movingbanners from "../Components/Moving-Banners/From-L-R/Movingbanners";
import Movingbanners2 from "../Components/Moving-Banners/From-T-B/Movingbanners2";
import Preloader from "../loader/preloader";
import whatsapp2 from "../Components/images/whatsapp2.png";
import tshirt from "../Components/images/ShopByCategory/SBC.png";
import longKurta from "../Components/images/ShopByCategory/SBC1.png";
import pants from "../Components/images/ShopByCategory/SBC2.png";
import shortKurta from "../Components/images/ShopByCategory/SBC31.png";
import shirt from "../Components/images/ShopByCategory/SBC4.png";
import coords from "../Components/images/ShopByCategory/SBC5.png";
import bgcircle from "../Components/images/bgcircle.png";
import logo from "../Components/images/logo2.png";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("Logged in");
    } else {
      console.log("Logged out");
    }
  }, [isAuthenticated]);

  const [ShowPopup, setShowPopup] = useState(false);
  // setTimeout(() => {
  //     setShowPopup(true)
  // }, 14700);
  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 21000);

    return () => clearTimeout(popupTimer);
  }, []);
  const handleBanner = () => {
    window.location =
      "/shop?category=Pyjama+Coord&color=&pattern=&fabric=&size=";
  };
  const [progress, setProgress] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const whatsappClick = () => {
    window.open("https://wa.me/919324283876", "_blank");
  };
  // Simulating progress change
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 25
      );
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setLoadingComplete(true);
      }, 500);
    }
  }, [progress]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className="home-main">
      <Preloader progress={progress} loadingComplete={loadingComplete} />
      <div className="home-productNav-main">
        <h1 className="home-productNav-main-header">SHOP LINEN BY CATEGORY</h1>
        <div className="home-productNav">
          <Link to="/shop?" style={{ textDecoration: "none" }}>
            <div className="home-productNav-child">
              <img
                src={logo}
                className="home-productNav-childImg-logo"
                alt="Linen"
              />
              <img
                src={bgcircle}
                className="home-productNav-childImgbg"
                alt="Linen"
              />
              <h1 className="home-productNav-childSubheader">Shop All</h1>
            </div>
          </Link>
          {/* <Link
            to="/shop?category=T-Shirt&color=&pattern=&fabric=&size="
            style={{ textDecoration: "none" }}
          >
            <div className="home-productNav-child">
              <img
                src={tshirt}
                className="home-productNav-childImg"
                style={{ width: "4rem", paddingTop: "0.5rem" }}
                alt="Linen"
              />
              <img
                src={bgcircle}
                className="home-productNav-childImgbg"
                alt="Linen"
              />
              <h1 className="home-productNav-childSubheader">Tees</h1>
            </div>
          </Link> */}
          {/* <Link
            to="/shop?category=Long+Kurta&color=&pattern=&fabric=&size="
            style={{ textDecoration: "none" }}
          >
            <div className="home-productNav-child">
              <img
                src={longKurta}
                className="home-productNav-childImg"
                alt="Linen"
              />
              <img
                src={bgcircle}
                className="home-productNav-childImgbg"
                alt="Linen"
              />
              <h1 className="home-productNav-childSubheader">LongKurta</h1>
            </div>
          </Link> */}
          <Link
            to="/shop?category=Coord+Set&color=&pattern=&fabric=&size="
            style={{ textDecoration: "none" }}
          >
            <div className="home-productNav-child">
              <img
                src={coords}
                className="home-productNav-childImg"
                style={{ width: "5.5rem" }}
                alt="Linen"
              />
              <img
                src={bgcircle}
                className="home-productNav-childImgbg"
                alt="Linen"
              />
              <h1 className="home-productNav-childSubheader">CoOrds</h1>
            </div>
          </Link>
          <Link
            to="/shop?category=Short+Kurta&color=&pattern=&fabric=&size="
            style={{ textDecoration: "none" }}
          >
            <div className="home-productNav-child">
              <img
                src={shortKurta}
                className="home-productNav-childImg"
                style={{ width: "4.5rem" }}
                alt="Linen"
              />
              <img
                src={bgcircle}
                className="home-productNav-childImgbg"
                alt="Linen"
              />
              <h1 className="home-productNav-childSubheader">ShortKurta</h1>
            </div>
          </Link>
          <Link
            to="/shop?category=Shirt&color=&pattern=&fabric=&size="
            style={{ textDecoration: "none" }}
          >
            <div className="home-productNav-child">
              <img
                src={shirt}
                className="home-productNav-childImg"
                style={{ width: "3.9rem", paddingTop: "0.5rem" }}
                alt="Linen"
              />
              <img
                src={bgcircle}
                className="home-productNav-childImgbg"
                alt="Linen"
              />
              <h1 className="home-productNav-childSubheader">Shirt</h1>
            </div>
          </Link>
          {/* <Link to="/shop?category=Pants&color=&pattern=&fabric=&size=">
            <div className="home-productNav-child">
              <img
                src={pants}
                className="home-productNav-childImg"
                style={{ width: "4rem", paddingTop: "0.5rem" }}
                alt="Linen"
              />
              <img
                src={bgcircle}
                className="home-productNav-childImgbg"
                alt="Linen"
              />
              <h1 className="home-productNav-childSubheader">Pants</h1>
            </div>
          </Link> */}
        </div>
      </div>
      <div className="carousel">
        <Carousel />
      </div>
      <Movingbanners />
      <Featuring />
      {/* <Banner/> */}
      {/* <CardDemo/> */}
      {/* <ProductList/> */}
      {/* <CardList/> */}
      {ShowPopup && <PopupSignIn onClose={handleClosePopup} />}
      <img
        className="co-ords"
        onClick={handleBanner}
        src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/6C.jpg?alt=media&token=ffca6e85-673c-4feb-9ae6-c7adc8cc046f"
        alt="banner"
      />
      <Movingbanners2 />
      <a href="/">
        <img
          className="whatsbutton-home"
          onClick={whatsappClick}
          src={whatsapp2}
          alt="socialbutton"
        />
      </a>
    </div>
  );
};

export default Home;
