import React from "react";

const FAQs = () => {
  const whatsapp = () => {
    window.open("https://wa.me/919324283876", "_blank");
  };
  return (
    <div className="Main-FC">
      <p
        style={{
          color: "#82480C",
          paddingLeft: "0rem",
          fontSize: "0.9rem",
          fontFamily: "'IBM Plex Mono', monospace",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none", color: "#82480C" }}>
          Home{" "}
        </a>
        <span style={{ cursor: "default", color: "#82480C" }}>{"< FAQs"} </span>
      </p>
      <h1 className="Header-FC">FAQ'S:</h1>
      <h1 className="SubHeader-FC">
        What is Diosdamroos and what kind of products do you offer?
      </h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We are a clothing brand based in Mumbai. Self manufacturers of fine
        cotton and linen. Established in 1988, Dios has two flagship stores in
        Sion, Mumbai where we deal with our solely designed and crafted
        materials that bring a natural balance between style and comfort. That
        is the goal, the intention.
      </p>

      <h1 className="SubHeader-FC">
        How do I care for my linen shirts from Dios to ensure they last a long
        time?
      </h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Luxurious, breathable and versatile, linen has a special place in our
        hearts. Starting from the loom, our linen is woven to create
        high-quality fabrics that will stand the test of time. Some suggestions
        for longevity -
      </p>

      <h1 className="SubHeader-FC">Washing</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        While washing, keep clothing zipped or buttoned up to avoid accidental
        snags on hooks and other sharp objects in the washing machine. Wash
        similar colors together to avoid any accidental color transfers. If the
        product has embellishments, embroidery, or beading, gently soak it and
        then wash it by hand. After washing, hang the clothing to dry away from
        direct light.
      </p>

      <h1 className="SubHeader-FC">SOS Stains</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        In case of any stains, spot-clean them with soap as soon as you can, and
        then wash the garment in cold water with a gentle detergent. Each
        garment comes with a care label to help you ensure its longevity.
      </p>

      <h1 className="SubHeader-FC">Ironing And Storage</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Linen is best ironed while damp. Since it gets softer with each
        consecutive wash, you will be able to enjoy your linen clothing more and
        more as time passes
      </p>

      <h1 className="SubHeader-FC">
        What makes Dios linen and cotton fabric different from other fabrics?
      </h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        At Dios, our fabrics embody the spirit of what we do. Our textiles are
        woven by hand artisans across India. The fabrics are perfected after
        months of trial and experimentation, leading to unique patterns that
        Dios is synonymous with. Our linen yarn is dyed and woven into our
        signature patterns, then turned into ultra-wearable clothing. Linen
        becomes softer with each wash, so when you invest in a timeless piece,
        you can come back to it again and again––moisture-wicking and better
        every time.
      </p>

      <h1 className="SubHeader-FC">How do I track my order?</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Orders once processed and shipped can be tracked directly by getting in
        touch with us at{" "}
        <a class="Highlight-FC" href="/FAQs" onClick={whatsapp}>
          +91 93242 83876{" "}
        </a>{" "}
        or{" "}
        <a
          className="Highlight-FC"
          href="https://mail.google.com/mail/u/0/#inbox"
          target="_blank"
          rel="noreferrer"
        >
          diosdamroos1988@gmail.com
        </a>
        .
      </p>

      <h1 className="SubHeader-FC">
        Could I visit a Dios flagship store for a retail experience?
      </h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Of course. You can visit our retail outlet at this address any time of
        the week from 11 am-10 pm. Address - 6A1, Mangala Building, Opp DS High
        School, Sion West, Mumbai 400 022. We offer a cash-on-delivery facility
        for all orders within India.
      </p>

      <h1 className="SubHeader-FC">
        How does International Shipping And Returns work?
      </h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        International orders: Shipping and handling costs may vary based on
        product, packaging, size, volume, type, and other considerations. The
        shipping and handling charges are given at the time of check-out and
        customers will know about this before making any payments. Goods to
        international destinations are shipped on a DDU (Delivery Duty Unpaid)
        basis, which means displayed product prices are exclusive of all import
        duties.
        <br />
        <span className="Bullet-FC">&#10209;</span> As the recipient, you are
        liable for all import duties, customs, and local sales taxes levied by
        the country you are in, payment of these at the time of delivery is
        necessary to release your order from customs on arrival.
        <br />
        <span className="Bullet-FC">&#10209;</span>Offers: We may from time to
        time offer free international shipping as a bonus on our website, in
        which case the terms and conditions of that particular offer will be
        valid and the above will be terminated for that duration. Note - Due to
        COVID, international shipping is chargeable, and free international
        shipping has been discontinued for orders over a particular value.
        <br />
        <span className="Bullet-FC">&#10209;</span>For international
        transactions, we accept major credit cards (including MasterCard, Visa &
        American Express) and provide an option to pay using PayPal.
      </p>
    </div>
  );
};

export default FAQs;
