import React from "react";
import "./Philosophy.css";
import fabricImage from "./images/FABRIC.jpg";
import stitchImage from "./images/FINISHING.jpg";
import fitImage from "./images/FITTING.jpg";
import "./Philosophy.css";

const PhilosophyPage = () => {
  return (
    <div className="philosophy-container">
      <div className="philosophy-container-navigation">
        <p className="philosophy-container-navigation-body">
          <a href="/">{"Home "}</a>
          <span style={{ cursor: "default" }}>{"< Philosophy"} </span>
        </p>
      </div>
      <h1>WHAT IS OUR PHILOSOPHY?</h1>

      <p className="philosophy-container-p">
        Our sole purpose is not only to sell you a product. We aspire to follow
        certain ethics and meet specific industry standards merely because we
        are in it for the long haul. We follow a 3F philosophy that applies to{" "}
        <span className="highlight">EVERY</span> product we create.
      </p>

      <div className="content-section">
        <div className="content-with-image">
          <img src={fabricImage} alt="Fabric" />
          <h2>FABRIC BEAUTY</h2>
          <p className="philosophy-container-p">
            It all starts with not only good but great FABRIC. The fabric is the
            heart and soul of a shirt. It is directly related to how you feel as
            it touches your skin. The feel-good factor is important for us. We
            are solely dedicated to bringing you the finest handpicked fabric
            that not only looks and feels great, but keeps you at ease.
          </p>
        </div>
      </div>
      <div className="content-section">
        <div className="content-with-image">
          <img src={stitchImage} alt="Stitching" />
          <h2>DETAILED FINISHING</h2>
          <p>
            A great fabric needs to be taken care of with love by us in the
            factory before it reaches you. A good stitch is a good craft. We
            aspire to be a craftsman to bring you seamless stitching behind
            every product that leaves our door and reaches yours. It is in the
            details, where the magic lies. A garment is a work of art. We aspire
            to be artists.
          </p>
        </div>
      </div>
      <div className="content-section">
        <div className="content-with-image">
          <img src={fitImage} alt="Fit" />
          <h2>TAILORED FITTING</h2>
          <p>
            The fit is what sets the benchmark. It is what separates a great
            shirt from a good one. We have artistic hands behind the sewing
            machines that focus on delivering a consistent seam throughout.
            There is no compromise from our end which will always reflect on our
            end product. We want to see you own a Dios Shirt with pride.
          </p>
        </div>
      </div>
      <div className="vision-section">
        <h2>WE WANT TO REVOLUTIONIZE CLOTHING FOR YOU.</h2>
        <h2>THAT IS THE VISION OF OUR BRAND.</h2>
        <h2>THAT IS THE INTENTION.</h2>
      </div>
    </div>
  );
};

export default PhilosophyPage;
