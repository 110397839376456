import React from "react";

const DeliveryAndExchange = () => {
  const whatsapp = () => {
    window.open("https://wa.me/919324283876", "_blank");
  };

  return (
    <div className="Main-FC">
      <p
        style={{
          color: "#82480C",
          paddingLeft: "0rem",
          fontSize: "0.9rem",
          fontFamily: "'IBM Plex Mono', monospace",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none", color: "#82480C" }}>
          Home{" "}
        </a>
        <span style={{ cursor: "default", color: "#82480C" }}>
          {"< Delivery & Exchange"}{" "}
        </span>
      </p>
      <h1 className="Header-FC">DELIVERY AND EXCHANGE:</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Delivery within 8-10 working days. ( Across India )
      </p>

      <h1 className="SubHeader-FC">WANT TO TRACK YOUR ORDER?</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        If you need assistance after placing an order, feel free to reach out to
        us at{" "}
        <a class="Highlight-FC" href="/delivery&Exchange" onClick={whatsapp}>
          +91 93242 83876{" "}
        </a>
        or click on the{" "}
        <a class="Highlight-FC" href="/delivery&Exchange" onClick={whatsapp}>
          Whatsapp Icon{" "}
        </a>
        on the home page. You can also write to us on{" "}
        <a
          className="Highlight-FC"
          href="https://mail.google.com/mail/u/0/#inbox"
          target="_blank"
          rel="noreferrer"
        >
          diosdamroos1988@gmail.com
        </a>
        . We will always be there for you.
      </p>

      <h1 className="SubHeader-FC">WANT YOUR ORDER URGENT?</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        If you require a placed order urgently, you can get in touch with us and
        we will do our best to reach your doorstep at the earliest. With a smile
        :D.
      </p>

      <h1 className="SubHeader-FC">WANT TO EXCHANGE AN ARTICLE?</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        You can always exchange any article if you are not satisfied with it. We
        will ensure that you get a new one in exchange that you like better.
        However, we will need 7 working days to do the same.
      </p>

      <h1 className="SubHeader-FC">
        WANT TO ADD SPECIAL INSTRUCTIONS TO YOUR ORDER?
      </h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        If you are sending a gift for someone through us, firstly we are
        flattered. Secondly, let us know if you need us to bring something
        special along with our article. We love writing handwritten letters :D.
      </p>
    </div>
  );
};

export default DeliveryAndExchange;
