import React from "react";

const FreeShipping = () => {
  const whatsapp = () => {
    window.open("https://wa.me/919324283876", "_blank");
  };

  return (
    <div className="Main-FC">
      <p
        style={{
          color: "#82480C",
          paddingLeft: "0rem",
          fontSize: "0.9rem",
          fontFamily: "'IBM Plex Mono', monospace",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none", color: "#82480C" }}>
          Home{" "}
        </a>
        <span style={{ cursor: "default", color: "#82480C" }}>
          {"< Free Shipping"}{" "}
        </span>
      </p>
      <h1 className="Header-FC">FREE SHIPPING:</h1>

      <h1 className="SubHeader-FC">How long will it take to ship my order?</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        All domestic orders take approximately 8-10 days to ship from our studio
        from the date of order receipt.
      </p>

      <h1 className="SubHeader-FC">How much will shipping cost?</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        NOTHING. The MRP on the article is the FINAL amount you will pay. All
        taxes and Shipping Charges are inclusive on the amount displayed as the
        MRP.
      </p>

      <h1 className="SubHeader-FC">
        Could I change the shipping address of the order?
      </h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Shipping details can be edited before your order is billed or shipped.
        However, all changes must be emailed within 24 hours of placing an order
        online.
      </p>
      <h1 className="SubHeader-FC">
        What if I am not home when the package is delivered?
      </h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Our delivery agents will attempt to deliver the package to you a couple
        of times before it is returned to us. Please provide your complete &
        accurate address including zip code and mobile number to ensure a
        seamless and quick delivery.
      </p>
    </div>
  );
};

export default FreeShipping;
