import React from "react";
import "./FooterComps.css";

const AboutUs = () => {
  const whatsapp = () => {
    window.open("https://wa.me/919324283876", "_blank");
  };
  return (
    <div className="Main-FC">
      <p
        style={{
          color: "#82480C",
          paddingLeft: "0rem",
          fontSize: "0.9rem",
          fontFamily: "'Lato', sans-serif",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none", color: "#82480C" }}>
          Home{" "}
        </a>
        <span style={{ cursor: "default", color: "#82480C" }}>
          {"< AboutUS"}{" "}
        </span>
      </p>
      <h1 className="Header-FC">ABOUT DIOS</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Since our establishment in 1988, Dios has worked to create a name for
        itself, especially in the central suburbs of Mumbai. People proudly own
        and wear a dios shirt for up to 15 years even today. That is who we are
        at the core.
      </p>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We love to hear from our satisfied clients about how we have
        successfully imagined and executed the art of making a good shirt. It
        not only brings us happiness but also inspires you. With{" "}
        <a className="Highlight-FC" href="/aboutUs">
          diosdamroos.com
        </a>
        , our vision is to provide you beautiful linens made from the finest
        European flax, finished to perfection with a fit you will never have to
        compromise on.
      </p>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        The intention is to make beautiful articles that make you feel as
        beautiful as you are. Your feel-good feeling is our end goal.
      </p>

      <p className="Last-FC">
        <span className="Bullet-FC">&#10209;</span>
        We are here to revolutionize clothing.
        <br />
        <br />
        <br />
        <span className="Bullet-FC">&#10209;</span>Talk to Us at{" "}
        <span className="Highlight-FC" onClick={whatsapp}>
          +91 93242 83876
        </span>
        <br />
        <br />
        <span className="Bullet-FC">&#10209;</span>Write To Us at{" "}
        <a
          className="Highlight-FC"
          href="https://mail.google.com/mail/u/0/#inbox"
          target="_blank"
          rel="noreferrer"
        >
          diosdamroos1988@gmail.com
        </a>
      </p>
    </div>
  );
};

export default AboutUs;
