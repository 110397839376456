import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { menuItems } from "../Body/menuItems";
import MenuItems from "./MenuItems";
import { Link, Navigate } from 'react-router-dom';
import {app,db,auth} from '../firebase';
import defaultProfile from './images/user.png';
import cart from './images/cart.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getDocs, collection, getFirestore } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import '../Body/FooterComponent.css'

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin-top:0;
  li {
    padding: 3px 8px;
  }
  .hiding-navbar-ele{
    display:none;
  }
  .footer-rightbar{
    display:none;
  }
  @media (max-width: 900px) {

    flex-flow: column nowrap;
    background-color: #F4EFEC;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    top: 5rem;
    left: 0;
    height: 100vh;
    width: 50%;
    padding-top: 3.5rem;
    transition: transform 0.5s ease-in-out;
    border:  1px #82480C solid;

    .footer-rightbar{
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      margin-top:2rem;
      width:100%;
    }
    .Bullet-FC-rightbar{
      color:#82480C;
      margin-bottom:2rem;
      font-size:1rem;
    }
    .Bullet-FC-rightbar2{
      color:#82480C;
      margin-top:3rem;
      font-size:1rem;
    }
    .li-rightbar{
      text-decoration:none;
      list-style:none;
      color: #82480C;
      width:100%;
      text-align:center;
    }
    .li-rightbar a{
      text-decoration: none;
      color: #82480C;
    }
    .li-rightbar li{
      font-size: 16px;
      font-family: "Playfair display",sans-serif;
      font-weight: 600;
    }
    .footer-social-media-rightbar{
      list-style:none;
      display:flex;
      margin-top: 2rem;
    }
    .footer-social-media-rightbar a{
      color: #82480C;
    }
    .hiding-navbar-ele{
      margin-top:1rem;
      display:flex;
      justify-content:center;
      align-items:center;
    }
    .Login-user2-sidebar{
      
    }
    .cart-navbar-sidebar{
      width: 1.5rem;
  }
  .Cart-user-sidebar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position:relative;
    cursor: pointer;
    border-bottom: 2px solid #82480C69;
}
  .Mycart-sidebar{
    font-family: "Playfair display",sans-serif;
    font-weight:600;
    font-size:1rem;
    color:#82480C;
  }
  .Mycart-sidebar-a{
  text-decoration:none;

}
.no-of-products-sidebar{
  cursor: default;
  position: absolute;
  top:0;
  right: 0;
  padding: 0px 2px;
  border-radius: 1rem;
  background-color: #82480C;
  color: #F4EFEC;
  font-size:0.7rem;

}
.link-login-sidebar{
  font-family:'Playfair Display', sans-serif;
  font-weight:600;
  text-decoration:none;
  color:#F4EFEC;
  padding: 1px 1rem;
  font-size:1rem;
  margin-top:1rem;
  border:2px solid #82480C;
  border-radius:6px;
  background-color: #82480C;
  text-align:center;
}
.logout-navbar-sidebar{
  font-family:'Playfair Display', sans-serif;
  font-weight:600;
  text-decoration:none;
  color:#F4EFEC;
  padding: 1px 1rem;
  font-size:1rem;
  margin-top:1rem;
  border:2px solid #82480C;
  border-radius:6px;
  background-color: #82480C;
  text-align:center;
}
  }
`;

const RightNav = ({ open }) => {
  const insta =()=>{
    window.open('https://www.instagram.com/dios_damroos/?igshid=MzNlNGNkZWQ4Mg%3D%3D', '_blank');
    }
  const facebook =()=>{
    window.open('https://www.facebook.com/diosbydamroos?mibextid=ZbWKwL', '_blank');
    }
  const whatsapp =()=>{
    window.open('https://wa.me/919324283876', '_blank');
    }

  const config = {
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0"
  };
  
  const uid = GetUserID();
    // console.log(uid)
    function GetUserID() {
      const [userID, setUID] = useState(null);
      useEffect(() => {
        auth.onAuthStateChanged(user => {
          if (user) {
            setUID(user.uid);
          }
        });
      }, []);
      return userID;
    }
      const [isHover,setIsHover] = useState(false)
      const handleMouseEnter = () => {
        setIsHover(true);
      };
    
      const handleMouseLeave = () => {
        setIsHover(false);
      };
      const [entryCount, setEntryCount] = useState(0);
      const app = firebase.initializeApp(config)
      const db = getFirestore(app)
      useEffect(() => {
        const fetchEntryCount = async () => {
          try {
            const querySnapshot = await getDocs(collection(db, `cart ${uid}`)); // Replace 'your-collection' with the name of your Firestore collection
            const count = querySnapshot.size; // Get the size/length of the querySnapshot
            setEntryCount(count);
          } catch (error) {
            console.error('Error fetching entry count:', error);
          }
          // getAuth()
          // getFirestore()
        };
        fetchEntryCount();
      }, [db,uid]);
      
    //   {/For Devang/}
      
    
      
      // initializeApp(firebaseConfig)
    
      // const auth = getAuth(app)
      
      const [user, setUser] = useState(null);
      useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            // User is signed in
            setUser(user);
            // console.log(user);      
          } else {
            // User is signed out
            setUser(null);
          }
        });
    
        // Clean up the listener when the component unmounts
        return () => unsubscribe();
      }, []);
    
      
      const handleLogOut = () => {
        auth.signOut()
        .then(() => {
          // Sign-out successful.
          console.log('User signed out successfully!');
        })
        .catch((error) => {
          // An error happened.
          console.error('Error signing out:', error);
        });
      }
  return (
    <Ul 
    open={open}className="menus">
      
        {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
                <MenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                />
            );
            })}
            <div className='footer-rightbar'>
            <span className='Bullet-FC-rightbar'>&#10209;&#10209;&#10209;</span>

          <ul className='li-rightbar'>
          <li>
            <a href="/aboutUs">About us</a>
          </li>
          <li>
          <a href="/t&c">Terms</a>
          </li>
          
          <li>
          <a href="/FAQs">FAQ</a>
          </li>
          {/* <li>
          <a href="/flagshipStore">Retail Journey</a>
          </li> */}
        </ul>
        <span className='Bullet-FC-rightbar2'>&#10209;&#10209;&#10209;</span>
        <ul class="footer-social-media-rightbar">
        <li>
          <a class="fb" href="/#" target='_blank' onClick={facebook}>
          <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </li>
        <li>
          <a class="insta" href="/#" target="_blank" onClick={insta} >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </li>
        <li>
          <a class="whatsapp" href="/#" onClick={whatsapp}>
          <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
        </li>
                    </ul>

            </div>
    </Ul>
  )
}

export default RightNav


