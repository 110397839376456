import React, { useEffect, useState } from "react";
import { BsBagCheckFill } from "react-icons/bs";
// import { useStateContext } from '../context/StateContext';
// import { runFireworks } from '../lib/utils';
import confetti from "canvas-confetti";
import "./success.css";
import emailjs from "emailjs-com";

import Movingbanners2 from "../Components/Moving-Banners/From-T-B/Movingbanners2";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";

const Successcod = () => {
  const whatsapp = () => {
    window.open("https://wa.me/919324283876", "_blank");
  };
  const { id } = useParams();
  const [trackingID, setTrackingID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const obj = {
    id: id,
  };
  const orderId = obj.id;

  const runFireworks = () => {
    var duration = 2 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  };

  // Replace 'your_order_id' with the actual ID you want to fetch
  // const { setCartItems, setTotalPrice, setTotalQuantities } = useStateContext();
  // const [order, setOrder]=useState(null);

  // useEffect(() => {
  //     localStorage.clear();
  //     setCartItems([]);
  //     setTotalPrice(0);
  //     setTotalQuantities(0);
  //     runFireworks();
  //     }, []);
  useEffect(() => {
    const db = firebase.firestore();
    const orderIdString = obj.id.toString();
    const orderRef = db.collection("orderDetails").where("tract_id", "==", id);

    orderRef
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            const orderData = doc.data();
            // Update the cod_status field to true for each document
            setUserEmail(orderData.billing_email);
            setTrackingID(id);
            // console.log(orderData.billing_email);
            doc.ref
              .update({ cod_status: true })
              .then(() => {
                console.log("Status updated successfully.");
              })
              .catch((error) => {
                console.error("Error updating payment status:", error);
              });
          });
        } else {
          console.log("No matching documents found.");
        }
      })
      .catch((error) => {
        console.error("Error getting order details:", error);
      });

    runFireworks(); // Run fireworks animation
  }, [id]);

  const sendEmail = (id, email) => {
    const templateParams = {
      user_email: email,
      trackingID: id,
    };

    emailjs
      .send(
        "service_rczsn4p",
        "template_akq83cg",
        templateParams,
        "mnO_bErlUZ7FWJjRJ"
      )
      .then(
        (result) => {
          console.log("Email Sent", result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  useEffect(() => {
    if (trackingID && userEmail && emailSent === false) {
      sendEmail(trackingID, userEmail);
      setEmailSent(true);
    }
  }, [trackingID]);
  return (
    <>
      <div className="success-wrapper">
        <div className="success">
          <p className="icon">
            <BsBagCheckFill />
          </p>
          <h2>Thank you for your order!</h2>
          <p className="email-msg">Check your email for the trackingId.</p>
          <p className="description">
            <p>
              Tracking ID: <span style={{ fontSize: "1.5rem" }}>{id}</span>{" "}
            </p>
            <p>Please copy the tracking id for your reference.</p>
            If you have any questions or query, <br></br>please email or contact
            to
            <a
              className="Highlight-FC"
              href="https://mail.google.com/mail/u/0/#inbox"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              diosdamroos1988@gmail.com
            </a>{" "}
            or
            <a
              className="Highlight-FC"
              href="/delivery&Exchange"
              onClick={whatsapp}
            >
              {" "}
              +91 93242 83876
            </a>
            .
          </p>
          {/* <a href="/shop"> */}
          {/* <button type="success-button" width="300px" className="success-button">
                        Continue Shopping
                    </button><br /><br /> */}
          <a className="fancy" style={{ marginTop: "1rem" }} href="/shop">
            <span className="top-key"></span>
            <span className="text21">Continue Shopping</span>
            <span className="bottom-key-1"></span>
            <span className="bottom-key-2"></span>
          </a>
          {/* </a> */}
        </div>
      </div>
      <a href="/shop?category=Coord+Set&color=&pattern=&fabric=&size=">
        <img
          className="co-ords"
          src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/6C.jpg?alt=media&token=ffca6e85-673c-4feb-9ae6-c7adc8cc046f"
          alt=""
        />
      </a>
      <Movingbanners2 />
    </>
  );
};

export default Successcod;
