import React, { useEffect, useState } from 'react';
import './Popup.css';
import image from './images/popup-image.jpg';
import 'animate.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import logo from './images/logo2.png';



const Popup = ({ onClose }) => {
  const [Submitted, setSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [Options, setOptions] = useState("Select Here");
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowPopup(true);
  //   },100);
  // }, []);

  useEffect(() => {
    if (Submitted) {
      const timeout = setTimeout(() => {
        onClose();
        setSubmitted(false)
      }, 1200);

      return () => clearTimeout(timeout);
    }
  }, [Submitted, onClose]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const email = currentUser.email;

    const docRef = await addDoc(collection(db, 'popUp'), { Options });
    console.log(docRef.id);
    toast.success('Thank you for the response ❤️', {
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: true,
    });
    setSubmitted(true);
    handleClose();

  };

  const handleOptions = (event) => {
    setOptions(event.target.value);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className={`popup-container ${showPopup ? 'show' : ''}`}>
      {showPopup && (
        <div className="popup-content2 animate__animated animate__fadeIn">
          {/* {Submitted ? (
            <div className="after-sub animate__animated animate__fadeOut">
              <div className="thank-you animate__animated animate__fadeOut">
                <span>THANK YOU FRIEND!</span>
              </div>
            </div>
          ) : (
            <> */}
            <div className='popup-img animate__animated animate__fadeIn'>
                <img src={image} alt="" />
            </div>
              
              <div className="input-container animate__animated animate__fadeIn">
                <button className="close-button-popup" onClick={handleClose}>
                  X
                </button>
                <div className="nav-area-Popup">
              <a href="/">
                <img  src={logo} alt="logo" />
              </a>
              </div>

                <p className="p2" id='p221'>Revolutionary Clothing Co.</p>
                <p className='p2' id='p222'>OH, HEY.</p>
                <p className="p" id='p223'>Welcome Home <br/>
                  We're Happy to see you!</p>
                <p className="p">Hey Friend, <br/>We're Happy to see you!</p>
                <p className="p2">How did you hear about us?</p>
                {/* <p className="p2" id='p224'>Please let us know below.</p> */}
                <div className='form animate__animated animate__fadeIn'>
                  <select
                    type="text"
                    name="source"
                    placeholder="Enter your source"
                    className="user-source"
                    value={Options}
                    onChange={handleOptions}
                  >
                    <option disabled>Select Here</option>
                    <option>Word Of Mouth</option>
                    <option>Social media ( Instagram, facebook, WhatsApp )</option>
                    <option>Search engine ( Google, Bing, etc )</option>
                    <option>Existing retail customer</option>
                    <option>Online Advertisement</option>
                    <option>Blog / Vlog</option>
                    <option>Outdoor / Physical Advertisement</option>
                    <option>Referral Code</option>
                  </select>
                <button className="submit21" type="submit" onClick={handleSubmit}>
                  Submit
                </button>
                </div>
                <button className="close-button-popup2" onClick={handleClose}>
                  Close X
                </button>
              </div>
            {/* </> */}
          
        </div>
      )}
      <ToastContainer
        progressStyle={{ visibility: 'hidden' }}
        autoClose={1500}
        closeButton={false}
        hideProgressBar={true}
      />
    </div>
  );
};

export default Popup;
