import React from 'react';
import { styled } from 'styled-components';
import Burger from './Burger';
import './navbar.css';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import { initializeApp } from 'firebase/app';
// import { Link } from 'react-router-dom';
// import cart from '../images/cart.png';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
// import { getDocs, collection, getFirestore } from 'firebase/firestore';
// import { Icon } from 'react-icons-kit';
// import {app,db,auth} from '../../firebase/firebase-config';
// import { config } from '@fortawesome/fontawesome-svg-core';
// import defaultProfile from '../images/user.png';

const Nav = styled.nav`
  .menus {
    display: flex;
    list-style: none;
  }
  @media only screen and (max-width: 900px) {
    z-index: 999;
    margin-top: 0rem;
  }
}`;

const Navbar = () => {
  return (
    <div>
      {/* <div className="navbr-header">
        <div className="moving-text-container">
          <p className="moving-text">Buy our new shirt, send your old. Get flat 10% off.</p>
        </div>
      </div> */}
      <div className="navbar-admin">
      {/* <div className="navbar" style={{ marginTop: `${navbarTop}rem` }}> */}
        <Nav>
          <Burger />
        </Nav>
        <div className="nav-area2">
        </div>
        {/* <div className="Login-user" >
        <div className='Cart-user'>
        <p className="no-of-products">{entryCount}</p>
        <Link to="/cart">
        <img src={cart} alt='' className='cart-navbar'/>
        </Link>
        </div>
        <div className='Profile-icon' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {user?.displayName ? (
        <img className='profile-user' src={user.photoURL} alt="" />
      ) : (
        <Link to="/login">
          <img className='default-user' src={defaultProfile} alt=""/>
        </Link>
      )}
      {isHover && (
        <div className="profile-user-details">
          {user?.displayName ? (
            <>
              <button className='logout-navbar' onClick={handleLogOut}>Log out</button> */}
              {/* <p>Name: <b>{user.displayName}</b></p> */}
            {/* </>
          ) : (
            <Link to="/login">
            <p className='link-login'>Login</p></Link>
          )}
        </div>
      )}
        </div>
        </div>
        */}
      </div> 
    </div>
  );
};

export default Navbar;
