import React from 'react';
import "./FooterComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope ,
        faPhone, 
        faClock,
} from "@fortawesome/free-solid-svg-icons";
import "animate.css";
import logoFooter from '../Components/images/logoFooter.png';
// import Footerbanner from './footerbanner';


export default function FooterComponent() {

  const insta =()=>{
    window.open('https://www.instagram.com/dios_damroos/?igshid=MzNlNGNkZWQ4Mg%3D%3D', '_blank');
    }
  const facebook =()=>{
    window.open('https://www.facebook.com/diosbydamroos?mibextid=ZbWKwL', '_blank');
    }
  const whatsapp =()=>{
    window.open('https://wa.me/919324283876', '_blank');
    }
  return (
    <div>
        <section className='section21'>
          {/* <Footerbanner/> */}
  
    {/* <div className="banner">
    <SectionsHead heading="Our Advantages"/>
    <div className="services_wrapper">
            {
              servicesData.map((item) => {
                const { id, icon, title, info } = item;
                
                return (
                  <div className="services_card" key={id}>
                  <div className="services_icon">{icon}</div>
                  <div className="services_details">
                  <h4>{title}</h4>
                  <p>{info}</p>
                  </div>
                  </div>
                  );
                })
              }
            </div> */}
      {/* <div className="flex">
        
        <div className="banner-content">
        <h2 className="banner-heading">
        FEATURES⚡⚡⚡
        </h2>
        </div>
        <div className="banner-action">
        <div className="cta-button">
        <a href="#" className="cta-link">
        SHOP NOW!
        </a>
        </div>
        </div>
      </div> */}

    {/* </div> */}
  <div className="container-footer">
    <div className="footer-links">
      
      <div className="footer-column">
        <div className="header">
        <a href="/#">
        {/* <h1 id='footer-head'>Dios Damroo's</h1> */}
        <img src={logoFooter} className='logo-footer'/>
        </a>
        <h4 >ESTD. 1988</h4>
        <br/>
        <p className='logo-btm1'>We aspire to revolutionize the idea of clothing for man.</p>
        <br/>
        <p className='logo-btm2'>Free Shipping | 15 days exchange | COD Available</p>
        </div>
      </div>
      <div className="footer-column">
        <h3 className="footer-heading">SHOP</h3>
        <ul>
          <li>
            <a href="/shop?category=Shirt&color=&pattern=&fabric=Linen&size=">Linen</a>
          </li>
          <li>
            <a href="/shop?category=Shirt&color=&pattern=&fabric=&size=">Shirt</a>
          </li>
          <li>
            <a href="/shop?category=Coord+Set&color=&pattern=&fabric=&size=">Co-ord</a>
          </li>
          <li>
            <a href="/shop?category=Short+Kurta&color=&pattern=&fabric=&size=">Short Kurta</a>
          </li>
          <li>
            <a href="/shop?category=Long+Kurta&color=&pattern=&fabric=&size=">Long Kurta</a>
          </li>
        </ul>
      </div>
      <div className="footer-column">
      {/* <TrackVisibility>
                            {({isVisible})=>
                              <div className={isVisible ? "animate__animated animate__fadeIn": ""}> */}
        <h3 className="footer-heading">INFORMATION</h3>
        <ul>
          <li>
            <a href="/aboutUs">About us</a>
          </li>
          <li>
          <a href="/t&c">Terms &amp; Conditions</a>
          </li>
          
          <li>
          <a href="/FAQs">FAQ</a>
          </li>
          <li>
          <a href="/flagshipStore">Retail Journey</a>
          </li>
        </ul>
        {/* </div>}
                          </TrackVisibility> */}
      </div>
      
      
      <div className="footer-column">
      {/* <TrackVisibility>
                            {({isVisible})=>
                              <div className={isVisible ? "animate__animated animate__fadeIn": ""}> */}
        <h3 className="footer-heading">MANAGE</h3>
        <ul>
          <li>
            <a href="/returns&Refunds">Returns &amp; Refunds</a>
          </li>
          <li>
            <a href="/delivery&Exchange">Delivery &amp; Exchange</a>
          </li>
          <li>
            <a href="/freeShipping">Free Shipping</a>
          </li>
          <li>
          <a href="/privacyPolicy">Privacy Policy</a>
          </li>
        </ul>
        {/* </div>}
                          </TrackVisibility> */}
      </div>
      <div className="footer-column">
      {/* <TrackVisibility>
                            {({isVisible})=>
                              <div className={isVisible ? "animate__animated animate__fadeIn": ""}> */}
        <h3 className="footer-heading">CONNECT  WITH US</h3>
        <ul className='footer-contactus'>
          <li onClick={whatsapp} style={{cursor:'pointer'}}><i className='phone'>
          <FontAwesomeIcon icon={faPhone} /></i>
          <span className='span-phone-footer'>+91 9324283876</span>
          </li>
          <li><i className='envelope'>
          <FontAwesomeIcon icon={faEnvelope} /></i>
          <a href='https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSDbFXbftzlbKnJrvCfrgBqJvmQSsXHBDfVgBFDHgFDnBnnNJMcQfCRGlVNhnVHNlXSwcVCS' target='_blank'rel="noreferrer">diosdamroos1988@gmail.com</a>
          </li>
          <li>
            <i className='clock'>
            <FontAwesomeIcon icon={faClock} /></i>
          Mon-Sat | 10 AM to 6 PM(IST)
          </li>
        </ul>
        
        <ul class="footer-social-media">
                                      <li>
                                        <a class="fb" href="/#" target='_blank' onClick={facebook}>
                                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                                        </a>
                                      </li>
                                      <li>
                                        <a class="insta" href="/#" target="_blank" onClick={insta} >
                                          <FontAwesomeIcon icon={faInstagram} size="2x" />
                                        </a>
                                      </li>
                                      <li>
                                        <a class="whatsapp" href="/#" onClick={whatsapp}>
                                        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                                        </a>
                                      </li>
                    </ul>
                    {/* </div>}
                          </TrackVisibility> */}
      </div>
    </div>
    <div className='copyright'>
      <p>© 2000-2023 Dios Damroo's. All Rights Reserved.</p>

    </div>
  </div>
</section>

    </div>
  )
}
