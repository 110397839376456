import React, { useEffect, useState } from "react";
import "./popupSignin.css";

import "animate.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import logo from "./images/logo2.png";
import { useAuth0 } from "@auth0/auth0-react";

const PopupSignIn = ({ onClose }) => {
  const [Submitted, setSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [Options, setOptions] = useState("Select Here");
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowPopup(true);
  //   },100);
  // }, []);
  useEffect(() => {
    if (Submitted) {
      const timeout = setTimeout(() => {
        onClose();
        setSubmitted(false);
      }, 1200);

      return () => clearTimeout(timeout);
    }
  }, [Submitted, onClose]);

  const handleSubmit = () => {
    window.location.href = "/login";
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className={`popup-container ${showPopup ? "show" : ""}`}>
      {showPopup && (
        <div className="popup-content2 animate__animated animate__fadeIn">
          <div className="popup-img animate__animated animate__fadeIn">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/SigninPopup.jpg?alt=media&token=7f9ab747-1ba9-45c8-a520-4a8602aa5857&_gl=1*oe1vcy*_ga*MjIxNDk5Nzg0LjE2ODUzODI0NzA.*_ga_CW55HF8NVT*MTY5NzE4MjA2Ni4xNTkuMS4xNjk3MTgyMjE2LjQ3LjAuMA.."
              alt=""
            />
          </div>

          <div className="input-container animate__animated animate__fadeIn">
            <button className="close-button-popup" onClick={handleClose}>
              X
            </button>
            <div className="nav-area-Popup">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>

            {/* <p className="p2121" id='p221'>Revolutionary Clothing Co.</p> */}

            <p className="p21" id="p221">
              Hey, We Love Giving
              <br />
              Discounts :)
            </p>
            <p className="p1">
              Sign In To Avail 10% Off,
              <br /> On Every Purchase!
            </p>
            {/* <p className="p2" id='p224'>Please let us know below.</p> */}

            <button
              className="submit21 animate__animated animate__fadeIn"
              type="submit"
              onClick={() => loginWithRedirect()}
            >
              Sign In
            </button>
          </div>
          {/* </> */}
          <div className="MobilePopup">
            <div>
              <button className="close-button-popup2" onClick={handleClose}>
                X
              </button>
              <div className="LoginButtonPopUpDiv">
                <button className="LoginButtonPopUp" onClick={handleSubmit}>
                  Sign In
                </button>
              </div>
            </div>
            <div className="MobilePopup2">
              <img
                className="MobilePopup-image"
                src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/SigninPopupMobile.png?alt=media&token=4d8646ef-7082-4e86-a875-e8d6dc5bb76b&_gl=1*1h4eysg*_ga*MjIxNDk5Nzg0LjE2ODUzODI0NzA.*_ga_CW55HF8NVT*MTY5NzIxNDA4My4xNjEuMS4xNjk3MjE0MjE0LjYwLjAuMA.."
                alt="mobilePopup"
              />
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        progressStyle={{ visibility: "hidden" }}
        autoClose={1500}
        closeButton={false}
        hideProgressBar={true}
      />
    </div>
  );
};

export default PopupSignIn;
