import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";

function LoginTest() {
    const { loginWithRedirect } = useAuth0();
    
    return (
    <div>
    </div>
  )
}

export default LoginTest
