import React from 'react';
import Countdown from 'react-countdown-now';
import './flipclock.css'; // Import the CSS file for styling
import 'animate.css';

const FlipCountdown = () => {
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="flip-container">
        <div className="flip-item">
          <span className='animate__fadeInUp number'>{days}</span>
          <span className="writtenManner">Days</span>
        </div>
        <div className="flip-item">
          <span className='number'>{hours}</span>
          <span className="writtenManner">Hours</span>
        </div>
        <div className="flip-item">
          <span className='number'>{minutes}</span>
          <span className="writtenManner">Minutes</span>
        </div>
        <div className="flip-item">
          <span className='number'>{seconds}</span>
          <span className="writtenManner">Seconds</span>
        </div>
      </div>
    );
  };

  return (
    <div className="countdown-container">
      <Countdown date={new Date('2023-08-15T12:00:00')} renderer={renderer} />
    </div>
  );
};

export default FlipCountdown;
