import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./carousel.css";
// import banner5 from '../Components/Moving-Banners/From-L-R/5.jpg'
import heading from "../Components/images/Heading.png";
import "animate.css";
import bannerImage from "../Components/images/1c1.png";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const Carousel = () => {
  return (
    <>
      <div className="carousel-container">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          // pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
        >
          <SwiperSlide>
            <img
              className="carousel-image animate__animated animate__fadeInUp animate__animated animate__fadeInUp"
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FDESKTOP%2FCO-ORDS%20ARE%20A%20CULT.jpg?alt=media&token=f5ce590d-d04d-4923-b8bc-3d0fae148a70"
              alt="Banner 2"
            />
            {/* <img
              className="carousel-image animate__animated animate__fadeInUp animate__animated animate__fadeInUp"
              src={bannerImage}
              alt="Banner 2"
            /> */}
            <a href="/shop?category=Coord+Set&color=&pattern=&fabric=&size=">
              <button className="overlay-button2 animate__animated ">
                <p id="overlay-p2">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="carousel-image animate__animated animate__fadeInUp"
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FDESKTOP%2FHeading.jpg?alt=media&token=e5d9d834-0857-49de-a48f-66106e28c55f"
              alt="Banner 4"
            />
            <a href="/shop?category=Shirt&color=&pattern=&fabric=&size=">
              <button className="overlay-button2 animate__animated ">
                <p id="overlay-p2">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="carousel-image animate__animated animate__fadeInUp"
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FDESKTOP%2FLINEN%20PRINT%20DESKTOP.jpg?alt=media&token=e9cd83a5-9045-4089-bebc-8c4aae142e30"
              alt="Banner 1"
            />
            <a href="/shop?category=Shirt&color=&pattern=&fabric=Linen&size=">
              <button className="overlay-button2 animate__animated ">
                <p id="overlay-p2">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="carousel-image animate__animated animate__fadeInUp"
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FDESKTOP%2FCO-ORDS%20ARE%20A%20CULT%20copy.jpg?alt=media&token=8b96c1be-5486-4f08-8b3c-ff04f7dbfdbc"
              alt="Banner 3"
            />
            <a href="/shop?category=Coord+Set&color=&pattern=&fabric=&size=">
              <button className="overlay-button2 animate__animated ">
                <p id="overlay-p2">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          {/* <SwiperSlide>
          <img className="carousel-image animate__animated animate__fadeInUp" src={banner6} alt="Banner 7" />
        </SwiperSlide> */}
        </Swiper>
      </div>
      <div className="carousel-container-21">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
        >
          {/* <SwiperSlide>
            <img
              className="carousel-image21 animate__animated animate__fadeInUp"
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/1CM.jpg?alt=media&token=63dc25a7-be93-46ca-92ed-9ae1452af204"
              alt="Banner 6"
            />
            <a href="/shop?category=Coord+Set&color=&pattern=&fabric=&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide> */}
          <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              // src={heading}
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FMOBILE%2FLINEN%20COORDS.jpg?alt=media&token=dc2fa364-9a32-4ffb-b8c3-1fe67fa8c721"
              alt="Banner 7"
            />
            <a href="/shop?category=Shirt&color=&pattern=&fabric=Linen&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FMOBILE%2FLINEN%20PRINT%20PHONE.jpg?alt=media&token=cfa30514-684e-4a74-914b-8be5f0d0a7ad"
              alt="Banner 7"
            />
            <a href="/shop?category=Shirt&color=&pattern=Print&fabric=&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FMOBILE%2FSHIRT%20CHECK%20MOBILE.jpg?alt=media&token=9e5bdc77-78a0-43f1-80ef-55caadaf04fd"
              alt="Banner 7"
            />
            <a href="/shop?category=Shirt&color=&pattern=&fabric=Linen&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/Banners%2FMOBILE%2FLINEN%20COORDS%20copy.jpg?alt=media&token=172bd2a0-12b3-4cf6-9092-3e82376e40fd"
              alt="Banner 7"
            />
            <a href="/shop?category=Coord+Set&color=&pattern=&fabric=&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          {/* <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/3CM.jpg?alt=media&token=66cd902e-b55f-48f1-842d-1052b38799cb"
              alt="Banner 1"
            />
            <a href="/shop?category=Coord+Set&color=&pattern=&fabric=&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/4CM.jpg?alt=media&token=6863714c-3e63-4490-b103-8de1898e7dfb"
              alt="Banner 3"
            />
            <a href="/shop?category=Long+Kurta&color=&pattern=&fabric=&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/5CM.jpg?alt=media&token=ff7d8f5d-c0d4-490e-87fa-0c73d6894542"
              alt="Banner 7"
            />
            <a href="/shop?category=Shirt&color=&pattern=Print&fabric=&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="carousel-image21 animate__animated "
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/6CM.jpg?alt=media&token=73149386-a40d-4ee1-aa25-7ffb5a0177e9"
              alt="Banner 7"
            />
            <a href="/shop?category=Shirt&color=&pattern=Checks&fabric=&size=">
              <button className="overlay-button animate__animated ">
                <p id="overlay-p">Shop Now</p>
              </button>
            </a>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </>
  );
};

export default Carousel;
