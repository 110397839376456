/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import ProductCard from "./card";
import "./all-products.css";
import { useLocation, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Checkbox } from "@mui/material";
import { CheckBox } from "@mui/icons-material";

const AllProducts = () => {
  // let { category } = useParams();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterColor, setFilterColor] = useState("");
  const [filterFabric, setFilterFabric] = useState("");
  const [filterPattern, setFilterPattern] = useState("");
  const [filterSize, setFilterSize] = useState("");
  const [categoryInfo, setCategoryInfo] = useState("");

  const [colorOptions, setColorOptions] = useState([]);
  const [fabricOptions, setFabricOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [patternOptions, setPatternOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  // // Insert in homepage: @Devang
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedPattern, setSelectedPattern] = useState("");
  const [selectedFabric, setSelectedFabric] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [age, setAge] = React.useState("");

  const handleChangeCategory = (event) => {
    setAge(event.target.value);
  };

  var categoryOptionss = [
    "Shirt",
    "Short Kurta",
    "Coord Set",
    "Pants",
    "Formal Pants",
    "Long Kurta",
    "Pyjama Coord",
    "Cotton Trouser",
    "T-Shirt",
  ];
  const [isLeftPaneVisible, setIsLeftPaneVisible] = useState(false); // State to track the visibility of the left-side pane

  const MAX_PAGES_DISPLAY = 5; // Maximum number of pagination buttons to display

  const getCategoryInfo = (category) => {
    var categoryOptionss = [
      "Shirt",
      "Short Kurta",
      "Coord Set",
      "Pants",
      "Formal Pants",
      "Long Kurta",
      "Pyjama Coord",
      "Cotton Trouser",
      "T-Shirt",
    ];

    const categoryFabricMap = {
      shirt: {
        heading: "Shirts",
        paragraph:
          "Made From European Flax, Fine Linens Lie At The Heart Of Style And Comfort. Ranges Include Different Styles Of Collars And Patterns. So Who Are You? Are You Plain Or Are You Printed?",
      },
      "coord set": {
        heading: "Coord Sets",
        paragraph:
          "Co-ords Are An Ongoing Cult. Shirt Or Kurta, Shorts Or Pyjamas, We Have Attempted To Redefine Style With An Indo-Western Take On Them. They Reek Of Class.",
      },
      "short kurta": {
        heading: "Short Kurta",
        paragraph:
          "There's Indian, Then There's Casual. What Lies In The Middle? The Short Kurta. Pair Them With Pants Or Denims. Try These As Loungewear, Try Indifferent.",
      },
      "long kurta": {
        heading: "Long Kurta",
        paragraph:
          "The Linen Long Kurta Is Classic. We Have Tried To Blend This Basic Style With Some Print. What Came Out Looks Beautiful. Feels More Beautiful Than It Looks.",
      },
      "2 pockets": {
        heading: "2 Pockets",
        paragraph:
          "1 Shirt. 1 Print. 2 Pockets. Classic Meets Offbeat. Pair These With Our Linen Pleated Pants, That's Your Outfit Of The Day For A Lot Of Days.",
      },
      "pleated pants": {
        heading: "Pleated Pants",
        paragraph:
          "Extra Stretch On The Waist, Slight Tapered Bottom And Comfortable Across The Front Folk. The OG Linen Pleated Pants Are Versatile.",
      },
      "cotton trouser": {
        heading: "Cotton Trouser",
        paragraph:
          "The Cotton Stretch Pants Are For An Evening To Chill Or A Morning To Work. Works Both Ways. Try Our Corduroy Stretch Pants, We Love How They Fit.",
      },
      "t-shirt": {
        heading: "Crew Neck Tee",
        paragraph:
          "We Should Have One Article For Seven Days, Always. We have more Than 7 Colours In These. One For Everyday Of The Week. Comfortable And Class, What A Blend.",
      },
      pants: {
        heading: "Pants",
        paragraph:
          "The Cotton Stretch Pants Are For An Evening To Chill Or A Morning To Work. Works Both Ways. Try Our Corduroy Stretch Pants, We Love How They Fit.",
      },
    };

    const categoryFabricKey = `${category?.trim()?.toLowerCase()}`;

    // Check if the combination exists in the mapping
    const selectedCategoryFabric = categoryFabricMap[categoryFabricKey];
    console.log(selectedCategoryFabric);
    return selectedCategoryFabric || null;
  };

  useEffect(() => {
    const firebaseConfig = {
      // Your Firebase configuration
      apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
      authDomain: "diosdamroo-b97a0.firebaseapp.com",
      projectId: "diosdamroo-b97a0",
      storageBucket: "diosdamroo-b97a0.appspot.com",
      messagingSenderId: "28288876745",
      appId: "1:28288876745:web:b52667cf7c41499a648d18",
      measurementId: "G-DFF1V6MBR0",
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    const db = firebase.firestore();

    const fetchProducts = async () => {
      let query = db
        .collection("productCode")
        .where("active_status", "==", true);

      if (category) {
        // query = query.where('category2', '==', selectedCategory);
        query = query.where("category2", "==", category);
      }
      if (color) {
        query = query.where("color", "==", color);
      }
      if (fabric) {
        query = query.where("fabric", "==", fabric);
      }
      if (pattern) {
        query = query.where("pattern", "==", pattern);
      }
      if (size) {
        query = query.where("available_sizes", "array-contains", size);
      }

      // query = query.orderBy('fabric', 'desc');

      const snapshot = await query.get();

      const selectedCategoryFabric = getCategoryInfo(category);
      setCategoryInfo(selectedCategoryFabric);

      const productsData = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          shortTitle: doc.data().small_title,
          bigTitle: doc.data().big_title,
          price: doc.data().price,
          fabric: doc.data().fabric,
          img1: doc.data().img1,
          img2: doc.data().img2,
          img3: doc.data().img3,
          img4: doc.data().img4,
          desc: doc.data().desc,
          cat1: doc.data().category1,
          cat2: doc.data().category2,
          availablesize: doc.data().available_sizes,
          product_code: doc.data().product_code,
          sku_code: doc.data().sku_code,
          pattern: doc.data().pattern,
          model_details: doc.data().model_details,
          wash_care: doc.data().wash_care,
          size_chart: doc.data().size_chart,
        };
      });

      productsData.sort((a, b) => {
        if (a.fabric === "linen" && b.fabric !== "linen") {
          return -1;
        } else if (a.fabric !== "linen" && b.fabric === "linen") {
          return 1;
        }
        return 0;
      });

      const storedImages = sessionStorage.getItem("productImages");
      if (storedImages) {
        const cachedProducts = JSON.parse(storedImages);
        const mergedProducts = productsData.map((product) => {
          const cachedProduct = cachedProducts.find(
            (cachedProduct) => cachedProduct.id === product.id
          );
          if (cachedProduct) {
            return { ...product, img1: cachedProduct.img1 };
          }
          return product;
        });
        setProducts(mergedProducts);
      } else {
        setProducts(productsData); // Use the sorted productsData array
        sessionStorage.setItem("productImages", JSON.stringify(productsData));
      }

      setProducts(productsData);
      setIsLoading(false);
    };

    const fetchColorOptions = async () => {
      const snapshot = await db
        .collection("productCode")
        .where("active_status", "==", true)
        .get();
      const colors = new Set();

      snapshot.docs.forEach((doc) => {
        const color = doc.data().color;
        if (color) {
          colors.add(color);
        }
      });

      setColorOptions(Array.from(colors));
    };

    const fetchFabricOptions = async () => {
      const snapshot = await db
        .collection("productCode")
        .where("active_status", "==", true)
        .get();
      const fabrics = new Set();

      snapshot.docs.forEach((doc) => {
        const fabric = doc.data().fabric;
        if (fabric) {
          fabrics.add(fabric);
        }
      });

      setFabricOptions(Array.from(fabrics));
    };

    const fetchCategoryOptions = async () => {
      const snapshot = await db
        .collection("productCode")
        .where("active_status", "==", true)
        .get();
      const categories = new Set();

      snapshot.docs.forEach((doc) => {
        const category = doc.data().category2;
        if (category) {
          categories.add(category); // Convert to lowercase and trim
        }
      });
      setCategoryOptions(Array.from(categories));
    };

    const fetchPatternOptions = async () => {
      const snapshot = await db
        .collection("productCode")
        .where("active_status", "==", true)
        .get();
      const patterns = new Set();

      snapshot.docs.forEach((doc) => {
        const pattern = doc.data().pattern;
        if (pattern) {
          patterns.add(pattern); // Convert to lowercase and trim
        }
      });

      setPatternOptions(Array.from(patterns));
    };

    const fetchSizeOptions = async () => {
      const snapshot = await db
        .collection("productCode")
        .where("active_status", "==", true)
        .get();
      const sizes = new Set();

      snapshot.docs.forEach((doc) => {
        const availableSizes = doc.data().available_sizes;
        if (availableSizes && Array.isArray(availableSizes)) {
          availableSizes.forEach((size) => {
            sizes.add(size);
          });
        }
      });

      setSizeOptions(Array.from(sizes).sort(compareSizes));
    };

    fetchProducts();
    fetchCategoryOptions();
    fetchColorOptions();
    fetchPatternOptions();
    fetchFabricOptions();
    fetchSizeOptions();

    const queryParams = new URLSearchParams();
    // if (selectedCategory) queryParams.set('type:', selectedCategory);
    if (selectedColor) queryParams.set("color:", selectedColor);
    if (selectedPattern) queryParams.set("pattern:", selectedPattern);
    if (selectedFabric) queryParams.set("selectedFabric", selectedFabric);

    if (selectedSize) queryParams.set("selectedSize", selectedSize);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState(null, "", newUrl);
  }, [filterCategory, filterColor, filterFabric, filterSize, filterPattern]);

  const capitalizeFirstLetter = (str) => {
    if (str === null || str === undefined) {
      return ""; // If the input is null or undefined, return an empty string
    }
    return str
      .trim()
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  console.log(sizeOptions);
  function compareSizes(a, b) {
    a = String(a);
    b = String(b);

    const sizeOrder = ["M", "L", "XL", "2XL", "3XL", "4XL", "5XL"];

    // Check if both a and b are sizes
    if (sizeOrder.includes(a) && sizeOrder.includes(b)) {
      return sizeOrder.indexOf(a) - sizeOrder.indexOf(b);
    }

    // If either a or b is a size, prioritize it
    if (sizeOrder.includes(a)) {
      return -1;
    }
    if (sizeOrder.includes(b)) {
      return 1;
    }

    // Both are numbers, compare them numerically
    return a - b;
  }

  sizeOptions.sort(compareSizes);
  console.log(sizeOptions);
  // ===========================================================================================================
  const location = useLocation();
  const navigate = useNavigate();

  const handleCategorySelect = (selectedValue) => {
    const searchParams = new URLSearchParams(location.search);

    setFilterCategory(selectedValue);
    searchParams.set("category", selectedValue);
    searchParams.set("color", "");
    searchParams.set("pattern", "");
    searchParams.set("fabric", "");
    searchParams.set("size", "");

    // Update the URL with the new parameter
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");

  const handleColorSelect = (selectedValue) => {
    const searchParams = new URLSearchParams(location.search);
    setFilterColor(selectedValue);
    searchParams.set("category", filterCategory);
    searchParams.set("color", selectedValue);
    searchParams.set("pattern", "");
    searchParams.set("fabric", "");
    searchParams.set("size", "");
    // Update the URL with the new parameter
    navigate(`${location.pathname}?${searchParams.toString()}`);
    // window.location.reload();
  };
  const color = queryParams.get("color");

  const handlePatternSelect = (selectedValue) => {
    setFilterPattern(selectedValue);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("category", filterCategory);
    searchParams.set("color", filterColor);
    searchParams.set("pattern", selectedValue);
    searchParams.set("fabric", "");
    searchParams.set("size", "");
    // Update the URL with the new parameter
    navigate(`${location.pathname}?${searchParams.toString()}`);
    // window.location.reload();
  };
  const pattern = queryParams.get("pattern");

  const handleFabricSelect = (selectedValue) => {
    const searchParams = new URLSearchParams(location.search);
    setFilterFabric(selectedValue);
    searchParams.set("category", filterCategory);
    searchParams.set("color", filterColor);
    searchParams.set("pattern", filterPattern);
    searchParams.set("fabric", selectedValue);
    searchParams.set("size", "");
    // Update the URL with the new parameter
    navigate(`${location.pathname}?${searchParams.toString()}`);
    // window.location.reload();
  };
  const fabric = queryParams.get("fabric");

  const handleSizeSelect = (selectedValue) => {
    const searchParams = new URLSearchParams(location.search);
    setFilterSize(selectedValue);
    searchParams.set("category", filterCategory);
    searchParams.set("color", filterColor);
    searchParams.set("pattern", filterPattern);
    searchParams.set("fabric", filterFabric);
    searchParams.set("size", selectedValue);
    // Update the URL with the new parameter
    navigate(`${location.pathname}?${searchParams.toString()}`);
    // window.location.reload();
  };
  const size = queryParams.get("size");

  function handleApply() {
    window.location.reload();
  }

  // ===========================================================================================================

  const toggleLeftPane = () => {
    setIsLeftPaneVisible(!isLeftPaneVisible);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      scroll.scrollToTop({
        duration: 900,
        smooth: "easeInOutQuad",
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      scroll.scrollToTop({
        duration: 900,
        smooth: "easeInOutQuad",
      });
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    scroll.scrollToTop({
      duration: 900,
      smooth: "easeInOutQuad",
    });
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    scroll.scrollToTop({
      duration: 900,
      smooth: "easeInOutQuad",
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    scroll.scrollToTop({
      duration: 900,
      smooth: "easeInOutQuad",
    });
  };
  const checkCategory = (category) => {
    return true;
  };

  // const selectedcat = getCategoryInfo(category);
  // setCategoryInfo(selectedcat);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const paginationButtons = [];
  const startPage = Math.max(
    1,
    currentPage - Math.floor(MAX_PAGES_DISPLAY / 2)
  );
  const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAY - 1);

  for (let page = startPage; page <= endPage; page++) {
    paginationButtons.push(
      <button
        key={page}
        className={`pagination-btn ${currentPage === page ? "active" : ""}`}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </button>
    );
  }

  return (
    <>
      <div className="left-main">
        <div className={`left-pane ${isLeftPaneVisible ? "visible" : ""}`}>
          {/* <h2>Filters</h2> */}
          <div className="filters">
            <div className="filter-group">
              <h5 className="filter-label">Category:</h5>
              {/* <CheckBox  /> */}
              {categoryOptions.map((category) => (
                <div key={category}>
                  <label className="checkBox">
                    <Checkbox
                      id="ch1"
                      size="small"
                      className="colorText"
                      checked={filterCategory === category}
                      onChange={() => handleCategorySelect(category)}
                    />
                  </label>
                  <label className="colorText" htmlFor={category}>
                    {category}
                  </label>
                  <div className="transition"></div>
                </div>
              ))}
              {/* {category.trim().charAt(0).toUpperCase() + category.trim().slice(1).toLowerCase()} */}
            </div>

            <div className="filter-group">
              <h5 className="filter-label">Color:</h5>
              {colorOptions.map((color) => (
                <div key={color}>
                  <label className="checkBox">
                    {/* <input
                      type="checkbox"
                      id="ch1"
                      className="colorText"
                      value={color}
                      // checked={selectedCategory.includes(category)}
                      onChange={() => handleColorSelect(color)}
                    /> */}
                    <Checkbox
                      id="ch1"
                      size="small"
                      className="colorText"
                      checked={filterColor === color}
                      onChange={() => handleColorSelect(color)}
                    />
                  </label>
                  <label className="colorText" htmlFor={color}>
                    {color}
                  </label>
                  <div class="transition"></div>
                </div>
              ))}
              {/* <select
                className="filter-dropdown"
                value={color}
                onChange={(e) => handleColorSelect(e.target.value)}
              >
                <option value="">All</option>
                {colorOptions.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select> */}
            </div>

            <div className="filter-group">
              <h5 className="filter-label">Pattern:</h5>
              {patternOptions.map((pattern) => (
                <div key={pattern}>
                  <label className="checkBox">
                    <Checkbox
                      id="ch1"
                      size="small"
                      className="colorText"
                      checked={filterPattern === pattern}
                      onChange={() => handlePatternSelect(pattern)}
                    />
                  </label>
                  <label className="colorText" htmlFor={pattern}>
                    {pattern}
                  </label>
                  <div class="transition"></div>
                </div>
              ))}
              {/* <select
                className="filter-dropdown"
                value={pattern}
                onChange={(e) => handlePatternSelect(e.target.value)}
              >
                <option value="">All</option>
                {patternOptions.map((pattern) => (
                  <option key={pattern} value={pattern}>
                    {pattern}
                  </option>
                ))}
              </select> */}
            </div>

            <div className="filter-group">
              <h5 className="filter-label">Fabric:</h5>
              {fabricOptions.map((fabric) => (
                <div key={fabric}>
                  <label className="checkBox">
                    {/* <input
                      type="checkbox"
                      id="ch1"
                      value={fabric}
                      // checked={selectedCategory.includes(category)}
                      onChange={() => handleFabricSelect(fabric)}
                    /> */}
                    <Checkbox
                      id="ch1"
                      className="colorText"
                      size="small"
                      checked={filterFabric === fabric}
                      onChange={() => handleFabricSelect(fabric)}
                    />
                  </label>
                  <label className="colorText" htmlFor={fabric}>
                    {fabric}
                  </label>
                  <div class="transition"></div>
                </div>
              ))}
              {/* <select
                className="filter-dropdown"
                value={fabric}
                onChange={(e) => handleFabricSelect(e.target.value)}
              >
                <option value="">All</option>
                {fabricOptions.map((fabric) => (
                  <option key={fabric} value={fabric}>
                    {fabric}
                  </option>
                ))}
              </select> */}
            </div>

            <div className="filter-group">
              <h5 className="filter-label">Size:</h5>
              {sizeOptions.map((size) => (
                <div key={size}>
                  <label className="checkBox">
                    <Checkbox
                      id="ch1"
                      size="small"
                      className="colorText"
                      checked={filterSize === size}
                      onChange={() => handleSizeSelect(size)}
                    />
                  </label>
                  <label className="colorText" htmlFor={size}>
                    {size}
                  </label>
                  <div class="transition"></div>
                </div>
              ))}
              {/* <select
                className="filter-dropdown"
                value={size}
                onChange={(e) => handleSizeSelect(e.target.value)}
              >
                <option value="">All</option>
                {sizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select> */}
            </div>
            {/* <button onClick={handleApply} className="chkOut-cart-2">
              Apply
            </button> */}
          </div>

          <button className="close-button" onClick={toggleLeftPane}>
            X
          </button>
        </div>
      </div>
      <div
        className={`container ${isLeftPaneVisible ? "" : "left-pane-visible"}`}
      >
        <div className="main-container">
          {categoryInfo ? (
            <>
              <div className="head-para">
                <p className="head-para-container-navigation-body">
                  <a href="/">{"Home "}</a>
                  <a href="/shop?">
                    <span>{"< Products"} </span>
                    <span style={{ cursor: "default" }}>
                      {"< "}
                      {categoryInfo.heading}
                    </span>
                  </a>
                </p>
                <h1 className="all">{categoryInfo.heading}</h1>
                <div className="para">
                  <p className="allp-p">{categoryInfo.paragraph}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="head-para">
                <p className="head-para-container-navigation-body">
                  <a href="/">{"Home "}</a>
                  <span style={{ cursor: "default" }}>{"< Products"} </span>
                </p>
                <h1 className="all">Core Collection</h1>
                <div className="para">
                  <p className="allp-p">
                    Explore Our Wide Range Of Shirts Known For Their
                    Versatility. Carry Them For Any Occasion With Our Linen
                    Pyjamas Or Pants, You'll Come Across Smooth. Shirt Or Kurta?
                    Linen Or Cotton? Plain Or Print? We Got Them All.
                  </p>
                </div>
              </div>
            </>
          )}

          {/* <button onClick={toggleLeftPane}>Toggle Left Pane</button>*/}
          <div className="filter-button">
            <button className="filterToggler" onClick={toggleLeftPane}>
              Filter
            </button>
            <select
              className="filterToggler"
              value={size}
              onChange={(e) => handleCategorySelect(e.target.value)}
            >
              <option value="">Category</option>
              {categoryOptions.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <div>
              <select
                className="filterToggler"
                value={size}
                onChange={(e) => handleSizeSelect(e.target.value)}
              >
                <option value="">Size</option>
                {sizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* <filterPane showPane={isLeftPaneVisible} onClose={toggleLeftPane}/> */}

          <div className="card-container">
            {isLoading ? (
              <div className="preloader">
                <div className="skeleton"></div>
              </div>
            ) : (
              //<div className="preloader"></div>
              currentItems.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))
            )}
          </div>

          <div className="pagination-container">
            <button
              className="pagination-btn"
              disabled={currentPage === 1}
              onClick={handleFirstPage}
            >
              First
            </button>
            <button
              className="pagination-btn"
              disabled={currentPage === 1}
              onClick={handlePrevPage}
            >
              Prev
            </button>
            {paginationButtons}
            <button
              className="pagination-btn"
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              Next
            </button>
            <button
              className="pagination-btn"
              disabled={currentPage === totalPages}
              onClick={handleLastPage}
            >
              Last
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
