import React from 'react';
import './preloader.css';

import 'animate.css';

const Preloader = ({ progress, loadingComplete }) => {
return (
    <div className={`preloader-container${loadingComplete ? ' hidden' : ''}`}>
    <div className="logo-container">
        <img src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/logo2.png?alt=media&token=ed1d8f29-04bc-4374-bf52-d5cdf7f1e69b' alt="Logo" className="logo-preloader animate__animated animate__fadeIn" />
        <h2 className='logo-subtitle animate__animated animate__fadeIn'>Estd. 1988</h2>
    </div>
    <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
    </div>
    </div>
);
};

export default Preloader;