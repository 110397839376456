import React, { useState, useEffect } from "react";
import './productDetails.css';
import freeShip from '../Components/images/delivery-status.png';
import COD from '../Components/images/cash-on-delivery.png';
import delStat from '../Components/images/delivery-truck.png';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { v4 as uuidv4 } from 'uuid';
import { Navigate } from "react-router";
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";
import 'firebase/compat/firestore';
import 'firebase/firestore';
import { collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase'; 
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMailReply } from "@fortawesome/free-solid-svg-icons";

import { useAuth0 } from '@auth0/auth0-react';
import Popup1 from "./sizeChart/sizeChart";



export default function ProductDetails(temp) {
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();
  const navigate = useNavigate()
  const [cartItems, setCartItems] = useState([]);
  function handlePTP(j) {
    if (size === '') {
      toast.warn('You have to select a size first', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      var productCodes = j.product_code
      var selectedSize = size
      var e = j.price 
      console.log(j.price)
      navigate('/userForm',{state:{e,productCodes,cartItems,selectedSize}})
    }
  }
  const mailto =()=>{
    // window.open(`https://www.instagram.com?url=${encodeURIComponent(`https://www.diosdamroos.com/product/${convertedTitle}`)}`, '_blank');
    // window.open(`mailto:recipient@example.com?subject=CHECKOUT%20THIS%20PRODUCT.&body=${encodeURIComponent(`https://www.diosdamroos.com/product/${convertedTitle}`)}`, '_blank');
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=recipient%40example.com&su=CHECKOUT%20THIS%20PRODUCT.&body=${encodeURIComponent(`https://www.diosdamroos.com/product/${convertedTitle}`)}            (Copy the link).`,'_blank');  
  }
  const facebook =()=>{
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://www.diosdamroos.com/product/${convertedTitle}`)}`, '_blank');
    }
  const whatsapp =()=>{
    window.open(`https://api.whatsapp.com/send/?text=${encodeURIComponent(`Check out this product: www.diosdamroos.com/product/${convertedTitle}`)}`, '_blank');
    }
  

  const uid = GetUserID();

  function GetUserID() {
    const [userID, setUID] = useState(null);
    useEffect(() => {
      auth.onAuthStateChanged(user1 => {
        if (user1) {
          setUID(user1.uid);
        }
      });
    }, []);
    return userID;
  }
  const product = temp.temp
  const available_sizes = product.available_sizes
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isSizeChartPopupOpen, setSizeChartPopupOpen] = useState(false);
  const [size, selectSize] = useState('');
  const [isSizeClicked, setIsSizeClicked] = useState({});
  let convertedTitle = product.small_title.replace(/ /g, "-") 

  useEffect(() => {
    const initialSizeClicked = {};
    if (Array.isArray(available_sizes)) {
      available_sizes.forEach((size) => {
        initialSizeClicked[size] = false;
      });
      setIsSizeClicked(initialSizeClicked);
    }
  }, [available_sizes]);
  
  const handleSizeClick = (size) => {
    selectSize(`${size}`);
    const updatedSizeClicked = {};
    Object.keys(isSizeClicked).forEach((key) => {
      updatedSizeClicked[key] = key === size;
    });
    setIsSizeClicked(updatedSizeClicked);
  };

  const db = firebase.firestore();

  let tempProduct;
  function handleAddToCart() {
    if (size === '') {
      toast.warn('You have to select a size first', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (isAuthenticated) {
      const tempProduct = { ...product };
      for (const key in tempProduct) {
        if (tempProduct.hasOwnProperty(key) && tempProduct[key] === undefined) {
          delete tempProduct[key];
        }
      }
      tempProduct['qty'] = 1;
      tempProduct['selectedSize'] = size;
      tempProduct['totalProductPrice'] = tempProduct.qty * tempProduct.price;
      tempProduct['id'] = uuidv4();

      db.collection('cart ' + user.sub).doc(tempProduct.uid).set(tempProduct).then(() => {
       
        toast.success("Product Added To Your Cart", {
          position: "top-right",
          autoClose: 1000,
        });
      });
    } else {
      loginWithRedirect()
    }
  }
  
  

  const [isPopupVisible1, setPopupVisible1] = useState(false);

  const handleClick = () => {
    setPopupVisible1(true);
  };

  const handleClosePopup = () => {
    setPopupVisible1(false);
  };

  const sizes = Array.isArray(available_sizes) ? available_sizes : [];

  return (
    <>
    {isPopupVisible1 && (
      <Popup1
        isPopupVisible2={isPopupVisible1}
        handleClosePopup={handleClosePopup}
      />
    )}
    <div className="parentContainer-productDetails">
      <p className="productTitle-productDetails">{product.small_title}</p>
      <p className="productBigTitle-productDetails">{product.big_title}</p>
      <p className="price-productDetails">₹{product.price ? product.price.toLocaleString({ maximumFractionDigits: 2 }) : 'N/A'}</p>
      <div className="multipleSize-productDetails">
        {sizes.map((size, index) => (
          <p
            key={index}
            className={`sizeText-productDetails ${isSizeClicked[size] ? 'active-productDetails' : ''}`}
            onClick={() => handleSizeClick(size)}
          >
            {size}
          </p>
        ))}
      </div>
      <p className="modelDesc-productDetails">{product.model_details}</p>
      
      <p className="sizeChart-productDetails" onClick={() => handleClick(true)}>SIZE CHART</p>
    
    
      <button className="addToCart-productDetails" onClick={handleAddToCart}>ADD TO BAG</button>
      <button className="addToCart-productDetails"  onClick={()=>handlePTP(product)}>BUY NOW</button>

      <div className="productBenefits-productDetails">
        <div className="Benefits-productDetails">
          <img src={freeShip} height='40px' alt=""/>
          <p className="benefitsText-productDetails">14 Day Returns</p>
        </div>
        <div className="Benefits-productDetails">
          <img src={COD} height='40px' alt=""/>
          <p className="benefitsText-productDetails">Cash On Delivery</p>
        </div>
        <div className="Benefits-productDetails">
          <img src={delStat} height='40px' alt=""/>
          <p className="benefitsText-productDetails">Free Shipping</p>
        </div>
      </div>
      <div className="Share-productDetails">
      <p>SHARE</p>
      <div className="Share-productDetails-Icons">
      <a class="fb-productDetails" href={`${convertedTitle}`} onClick={facebook}>
      <FontAwesomeIcon icon={faFacebook} className="productDetails-Icon"/>
      </a>
      <a class="whatsapp-productDetails" href={`${convertedTitle}`} onClick={whatsapp}>
      <FontAwesomeIcon icon={faWhatsapp} className="productDetails-Icon"/>
      </a>
      <a class="mailto-productDetails" href={`${convertedTitle}`} onClick={mailto}>
      <FontAwesomeIcon icon={faEnvelope} className="productDetails-Icon"/>
      </a>
      </div>
      {/* <a href={`https://api.whatsapp.com/send/?text=${encodeURIComponent(`Check out this product: www.diosdamroos.com/product/${convertedTitle}`)}`} target="_blank" rel="noreferrer">Share</a> */}
      </div>
      <ToastContainer />
    </div>
    </>
  );
}
