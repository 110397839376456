import './sample.css';
import FlipCountdown from '../Components/flipclock';
import logo from '../Components/images/DDwhite.png';
import React, { useState, useEffect } from 'react';
import {  collection, addDoc } from 'firebase/firestore';
import  { db } from '../firebase';
//for popup
import sendIcon from '../Components/images/email-send.png'
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginForm from './login';
// import './toastify-custom.css';

import { auth } from '../firebase';



export default function CountDown() {
  const [email, setEmail] = useState('');
  
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, 'emailSubs'), { email });
      console.log('Email uploaded successfully with ID:', docRef.id);
      setEmail('');
      toast.success("You have subscribed successfully",{
        position: "top-right",
        autoClose: 1000.
      });
    } catch (error) {
      console.error('Error uploading email:', error);
    }
  };

  return (
    
    <div className="golden-border">
    <div className='writtenContent'>
   
    <div className='div-1'>
      <img src={logo} alt="Image description" className='img'/>
      
    </div>
    
    <div className="div-2">
      <h3 className='subText1'>WELCOME HOME.</h3>
      
    </div>  
    <div className='div-1'>
    <FlipCountdown/>   
    </div>
    <div className="div-3">
    <h3 className='subText2'>Join Our Family, Subscribe.</h3>
      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden',  }}>
      <input
      type="email" 
      value={email} 
      onChange={handleChange}
      className='merchTextBox'
      placeholder="Email"
        />
        <button
        className='outline-button'
        onClick={handleSubmit}
        >
          <img src={sendIcon} alt="send" height='18px' />
          </button>
          <ToastContainer/>
          </div>
          </div>
          </div>

      </div>
        );
      }
      



// <LoginForm isOpen={isPopupOpen} onClose={closePopup}/>
  //show popup after certain amount of time
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsPopupOpen(true);
  //   }, 5000);

  //   return () => clearTimeout(timer);
  // }, []);


  // //login Popup
  // const [isPopupOpen, setIsPopupOpen] = useState(false);

  // const openPopup = () => {
  //   setIsPopupOpen(true);
  // };

  // const closePopup = () => {
  //   setIsPopupOpen(false);
  // };



