import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './activities.css';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import Navbar from '../components/navbar/navbar';
export const Users = () => {
    const [data, setData] = useState([]);
    
    // useEffect(() => {
    //     const fetchData = async () => {
    //     try {
    //         const querySnapshot = await getDocs(collection(db, 'Users')); // Replace 'your_collection' with the name of your Firestore collection
    //         const newData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //         setData(newData);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    //     };
    
    //     fetchData();
    // }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
            const querySnapshot = await getDocs(collection(db, 'orderDetails'));
            const newData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setData(newData);
            } catch (error) {
            console.error('Error fetching data:', error);
            }
        };
        
        fetchData();
        }, []);
    return (
        <div className='logs'>
        <header>
        <Navbar />
      </header>
        <div>
        <h2>Users-Details</h2>
        <table>
        <thead>
        <tr>
            <th>Name</th>
            <th>Email-Id</th>
            <th>PhoneNumber</th>
            <th>Address</th>
            <th>ApartmentNumber</th>
            <th>Pincode</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
        </tr>
        </thead>
        <tbody>
        {data.map((item) => (
            <tr key={item.id}>
                <td>{item.billing_name}</td>
                <td>{item.billing_email}</td>
                <td>{item.billing_tel}</td>
                <td>{item.billing_address}</td>
                <td>{item.apartmentNumber}</td>
                <td>{item.billing_zip}</td>
                <td>{item.billing_city}</td>
                <td>{item.billing_state}</td>
                <td>{item.billing_country}</td>
            
            </tr>
        ))}
        </tbody>
    </table>
        </div>
        </div>
    );
    };
    
    