import React from "react";
import new1 from "./images/flagship/new1.jpg";
import new2 from "./images/flagship/new2.jpg";
import new3 from "./images/flagship/new3.jpg";
import new4 from "./images/flagship/new4.jpg";
import new5 from "./images/flagship/new5.jpg";
import new6 from "./images/flagship/new6.jpg";
import old1 from "./images/flagship/old1.jpg";
import old2 from "./images/flagship/old2.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./flagshipstore.css";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const RetailStoryPage = () => {
  return (
    <div className="container21">
      <div className="container21-navigation">
        <p className="container21-navigation-body">
          <a href="/">{"Home "}</a>
          <span style={{ cursor: "default" }}>{"< FlagshipStore"} </span>
        </p>
      </div>
      <h1>THE RETAIL JOURNEY</h1>
      <br />
      <br />
      <p id="sub-content-retail">
        Hi, I'm Yash. I am the fourth generation of this family in the apparel
        business.
      </p>
      <p id="sub-content-retail">
        Let us see things from my perspective.Ps - I'm A Little Filmy.
      </p>

      <div className="generation">
        <br />
        <br />
        <h2>The First Generation.</h2>
        <h3>(There is no evidence of any pictures, of course)</h3>
        <p>
          My great-grandfather, in the 1950s, owned something they would call a
          'pedhi' back then. It's a Sindhi/Gujarati term. In easy words, barely
          a notch above a hawker. A minimal shop raised upon the floor in the
          middle of a hundred others. (Imagine Wall Street). They dealt with raw
          materials used to manufacture handkerchiefs, clothes, etc. Simply,
          fabric resellers.
        </p>
      </div>

      <div className="generation">
        <br />
        <br />
        <h2>The Second Generation.</h2>
        <p>
          My grandfather started manufacturing handkerchiefs and supplying them
          to retailers. They sold handkerchiefs for one anna. ONE ANNA!! He
          somehow managed to build a home out of that. Decades of commendable
          hard work combined with unbreakable patience. Back in the day, money
          had real value. Anyway, filling my grandfather's shoes was my
          father's.
        </p>
      </div>

      <div className="generation2">
        <div className="content21">
          <h2>The Third Generation.</h2>
          {/* <h2>(Now Of Course, Rishi Kapoor)</h2> */}
          <p>
            Here's my father. Prakash Kukreja. He worked with my grandfather at
            these pedhi's, traveling from city to city, selling what they
            sourced and manufactured. Decades later we had our first retail
            outlet set up in the year 1988 in Sion, Mumbai. By the name Damroo's
            Boutique. (Like Lord Shiva's spiritual instrument, Damroo. It brings
            strength and courage) We started back in the day by importing
            apparel from China and Turkey. Some time passed and Damroo's
            remained a consistent success. In the early 2000s, we built our
            manufacturing unit that shook the market. Offered several franchises
            across Maharashtra from 2005-2010. A few years later, we decided to
            part ways due to manufacturing difficulties. Coming back to owning
            only one store. It went on smoothly throughout. In August 2022, we
            expanded big.
          </p>
        </div>

        <div className="carousel-container2">
          <Swiper slidesPerView={1} autoplay={{ delay: 3000 }}>
            <SwiperSlide>
              <img className="carousel-image2" src={old1} alt="Banner 2" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={old2} alt="Banner 4" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="generation2">
        {/* <img src={new1} alt="Fourth Generation" />
<img src={new2} alt="Fourth Generation" />
<img src={new3} alt="Fourth Generation" />
<img src={new4} alt="Fourth Generation" />
<img src={new5} alt="Fourth Generation" />
<img src={new6} alt="Fourth Generation" /> */}
        <div id="column-carousel" className="carousel-container2">
          <Swiper slidesPerView={1} autoplay={{ delay: 3000 }}>
            <SwiperSlide>
              <img className="carousel-image2" src={new1} alt="Banner 2" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new2} alt="Banner 4" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new3} alt="Banner 1" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new4} alt="Banner 3" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new5} alt="Banner 7" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new6} alt="Banner 7" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="content21">
          <h2>The Fourth Generation.</h2>
          {/* <h2>(That's me, Not Ranbir Kapoor)</h2> */}
          <p>
            By 2022, Damroo's branched out by the name of Dios Damroo's. All
            these years, Damroo's became big in central Mumbai. There never
            existed any idea of marketing our brand apart from what people spoke
            of it. 35 years of goodwill of customers that turned into family
            merely by 'word of mouth'. People spoke highly of our Cotton Khadi
            Shirts, they spoke about how welcoming we were to create a good
            clothing experience. Not a single penny was invested in marketing
            whatsoever. So if you are wondering why you have not heard about us
            yet, we both know why. August 21, 2022, we expanded into a 1500 sq
            ft store. The space got bigger, and so did the dream. We moved on to
            bigger machinery, factories, and thought. We expanded in all
            directions and started manufacturing fine linens. Almost a year and
            the love and support have been overwhelming. And now we bring you
            DiosDamroos.com. We hope to revolutionize clothing for you and for
            us.
          </p>
        </div>
        <div id="invert-column-carousel" className="carousel-container2">
          <Swiper slidesPerView={1} autoplay={{ delay: 3000 }}>
            <SwiperSlide>
              <img className="carousel-image2" src={new1} alt="Banner 2" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new2} alt="Banner 4" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new3} alt="Banner 1" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new4} alt="Banner 3" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new5} alt="Banner 7" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="carousel-image2" src={new6} alt="Banner 7" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="generation">
        <p>
          It has been over 35 years since Damroo's exists and now with
          diosdamroos.com, The clothing experience we will bring is about to hit
          its peak.
        </p>
        <h2>Welcome To This Journey.</h2>
        <p>Welcome Home.</p>

        <p>
          If you wish to have a retail experience at our store, feel free to
          contact our In-Store Sales Head, Karan with a single K. 9833912324.
        </p>
      </div>
    </div>
  );
};

export default RetailStoryPage;
