import React from 'react';
import './userEditForm.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {useLocation, useNavigate} from 'react-router-dom';

// import {auth} from '../firebase'
const firebaseConfig = {
    // Your Firebase configuration
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0"
  };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
// const uid = GetUserID();
//   console.log(uid)
//   function GetUserID() {
//     const [userID, setUID] = useState(null);
//     useEffect(() => {
//       auth.onAuthStateChanged(user => {
//         if (user) {
//           setUID(user.uid);
//         }
//       });
//     }, []);
//     return userID;
//   }
const firestore = firebase.firestore();
const EditForm = () => {
    const location = useLocation();
    // const [formData,setFormData] = React.useState({});
    // const formData = location.state;
    // const [formData, setFormData] = React.useState(location.state);
    // setFormData(location.state)
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        phoneNumber: '',
        country: '',
        apartmentNumber: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
      });

      React.useEffect(() => {
        if (location.state) {
          setFormData(location.state);
          console.log(formData);
        }
      }, [location.state]);
      
        const handleChange = (event) => {
          const { name, value } = event.target;
          setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        };
      
        const validateEmail = (email) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        };
      
        const validatePhoneNumber = (phoneNumber) => {
          const phoneNumberRegex = /^\d{10}$/;
          return phoneNumberRegex.test(phoneNumber);
        };
const navigate = useNavigate()
  const handlePlaceOrder = () => {
    if (!formData.name.trim()) {
      alert('Please enter your name.');
      return;
    }

    if (!validateEmail(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }

    if (!validatePhoneNumber(formData.phoneNumber)) {
      alert('Please enter a valid phone number (10 digits).');
      return;
    }

    firestore
      .collection('orderDetails')
      .add(formData)
      .then(() => {
        navigate("/userDashboard")
      })
      .catch((error) => {
        console.error('Error uploading data:', error);
      });

    setFormData({
      name: '',
      email: '',
      phoneNumber: '',
      country: '',
      // firstName: '',
      // lastName: '',
      address: '',
      apartmentNumber: '',
      city: '',
      state: '',
      pincode: ''
      // confirmation:'false'
    });
  };

  return (
    <>
    
    <div className="form-container">
      <p className='heading'>User Information</p>
      <div className="form-inputs">
        <label>
          Name:
          <input type="text" className="name-field" name="name" value={formData.name} onChange={handleChange} />
        </label>
        <label>
          Email:
          <input type="email" className="email-field" name="email" value={formData.email} onChange={handleChange} />
        </label>
        <label>
          Phone Number:
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </label>
        <h2 className="address-txt">User Details</h2>

        <div className="country-aptno">
            
            <label>
              Apartment Number:
              <input
                type="text"
                className=''
                name="apartmentNumber"
                value={formData.apartmentNumber}
                onChange={handleChange}
                />
            </label>
        </div>
        
        <label>
          Address:
          <input type="text" className="address-field" name="address" value={formData.address} onChange={handleChange} />
        </label>
        <label>
          State:
          <input type="text" className='state-field' name="state" value={formData.state} onChange={handleChange} />
        </label>

        <label>
          City:
          <input type="text" className='city-field' name="city" value={formData.city} onChange={handleChange} />
        </label>

        <label>
          Pincode:
          <input type="text" className='pincode-field' name="pincode" value={formData.pincode} onChange={handleChange} />
        </label>
        <label>
        Country:
        <input type="text" className="country-field" name="country" value={formData.country} onChange={handleChange} />
      </label>
      
      </div>
      <div className="btn">
        <button className="outline-button" onClick={handlePlaceOrder}>
          Save
        </button>
      </div>
    </div>
  </>
  );
};

export default EditForm;