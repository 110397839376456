import { useEffect } from "react";
import "./App.css";
import MainRouter from "./router";
import ReactGA from "react-ga4";

ReactGA.initialize("G-GPNKRTYSVG");

function App() {
  return (
    <>
      <MainRouter />
    </>
  );
}

export default App;
