import React, { useState } from 'react';
import { auth , app, storage } from '../firebase/firebase-config';
import { ref, uploadBytes, getUploadTaskSnapshot, getDownloadURL } from 'firebase/storage';
import 'firebase/compat/firestore';
import 'firebase/firestore';
import firebase from 'firebase/compat/app'

import './adminPanel.css';
import Navbar from '../components/navbar/navbar';

const AdminPanel = () => {
const firebaseConfig = {
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0"
    };
    
    if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    }
const db = firebase.firestore()
const [id, setId] = useState('');
const [desc, setDesc] = useState('');
const [price, setPrice] = useState('');
const [small_title, setsmall_title] = useState('');
const [big_title, setbig_title] = useState('');
const [img1, setImg1] = useState('');
const [img2, setImg2] = useState('');
const [img3, setImg3] = useState('');
const [img4, setImg4] = useState('');
const [category1, setcategory1] = useState('');
const [category2, setcategory2] = useState('');
const [available_sizes, setavailable_sizes] = useState('');
const [productCode, setProductCode] = useState('');
const [skuCode, setSkuCode] = useState('');
const [pattern, setPattern] = useState('');
const [size_chart, setSizeChart] = useState('');
const [fabric, setFabric] = useState('');
const [color, setColor] = useState('');
const [wash_care, setWashCare] = useState('');
const [model_details, setModelDetails] = useState('');
const [imageFiles, setImageFiles] = useState(Array.from({ length: 4 }, () => null)); // Array to hold uploaded image files

const handleImageChange = (e, index) => {
if (e.target.files[0]) {
setImageFiles(prevFiles => {
    const newFiles = [...prevFiles];
    newFiles[index] = e.target.files[0];
    return newFiles;
});
}
};

// const handleAddProduct = async (e) => {
// e.preventDefault();
// try {
//     await db.collection('productCode').doc(id).set({
//     id,
//     desc,
//     price,
//     small_title,
//     big_title,
//     img1,
//     img2,
//     img3,
//     img4,
//     category1,
//     category2,
//     available_sizes: available_sizes.split(',').map((size) => size.trim()),
//     product_code: productCode,
//     sku_code: skuCode,
//     pattern,
//     size_chart,
//     fabric,
//     color,
//     wash_care,
//     model_details,

//     });
//     console.log('Product added successfully!');
//     // Clear the input fields or perform any necessary actions
//     setId('');
//     setDesc('');
//     setPrice('');
//     setsmall_title('');
//     setbig_title('');
//     setImg1('');
//     setImg2('');
//     setImg3('');
//     setImg4('');
//     setcategory1('');
//     setcategory2('');
//     setavailable_sizes('');
//     setProductCode('');
//     setSkuCode('');
//     setPattern('');
//     setSizeChart('');
//     setFabric('');
//     setColor('');
//     setWashCare('');
//     setModelDetails('');
// } catch (error) {
//     console.error('Error adding product:', error);
// }
// };
const handleAddProduct = async (e) => {
e.preventDefault();

try {
    const productRef = db.collection('productCode').doc(id);

    // Upload images to Firebase Storage
    const uploadPromises = [];
    const imageRefs = ['img1', 'img2', 'img3', 'img4'];
    const imageFiles = [img1, img2, img3, img4];

    imageRefs.forEach((imageRef, index) => {
        if (imageFiles[index]) {
          const storageRef = ref(storage, `images/${id}/${imageRef}.jpg`);
          const uploadTask = uploadBytes(storageRef, imageFiles[index]);
          uploadPromises.push(uploadTask);
        }
      });
  
      await Promise.all(uploadPromises);
  
      // Get download URLs for uploaded images
      const downloadURLs = await Promise.all(
        imageRefs.map(async (imageRef) => {
          const imageUrl = await getDownloadURL(ref(storage, `images/${id}/${imageRef}.jpg`));
          return imageUrl;
        })
      );

    // Add product details to Firestore
    await productRef.set({
    id,
    desc,
    price,
    small_title,
    big_title,
    img1: downloadURLs[0] || '',
    img2: downloadURLs[1] || '',
    img3: downloadURLs[2] || '',
    img4: downloadURLs[3] || '',
    category1,
    category2,
    available_sizes: available_sizes.split(',').map((size) => size.trim()),
    product_code: productCode,
    sku_code: skuCode,
    pattern,
    size_chart,
    fabric,
    color,
    wash_care,
    model_details,
    });

    console.log('Product added successfully!');
    // Clear the input fields or perform any necessary actions
    setId('');
    setDesc('');
    setPrice('');
    setsmall_title('');
    setbig_title('');
    setImg1('');
    setImg2('');
    setImg3('');
    setImg4('');
    setcategory1('');
    setcategory2('');
    setavailable_sizes('');
    setProductCode('');
    setSkuCode('');
    setPattern('');
    setSizeChart('');
    setFabric('');
    setColor('');
    setWashCare('');
    setModelDetails('');
} catch (error) {
    console.error('Error adding product:', error);
}
};

return (
  <>
  <header>
  <Navbar />
</header>
<div className="admin-panel">
<h2>Add Product</h2>
<form onSubmit={handleAddProduct}>
<input
type="text"
placeholder="Main Product Code (Ex:GLK 00)"
value={id}
onChange={(e) => setId(e.target.value)}
/>
<textarea
placeholder="Description (Ex:Product-details)"
value={desc}
onChange={(e) => setDesc(e.target.value)}
></textarea>
<input
type="number"
placeholder="Price (Ex:5500)"
value={price}
onChange={(e) => setPrice(e.target.value)}
/>
<input
type="text"
placeholder="Short Title (Ex:Charcoal Abstract Kurta)"
value={small_title}
onChange={(e) => setsmall_title(e.target.value)}
/>
<input
type="text"
placeholder="Big Title (Ex:Charcoal Grey Cotton Abstract Print Men's Kurta)"
value={big_title}
onChange={(e) => setbig_title(e.target.value)}
/>
{/* <input
type="text"
placeholder="Image 1 (Ex:Upload Image)"
value={img1}
onChange={(e) => setImg1(e.target.value)}
/>
<input
type="text"
placeholder="Image 2 (Ex:Upload Image)"
value={img2}
onChange={(e) => setImg2(e.target.value)}
/>
<input
type="text"
placeholder="Image 3 (Ex:Upload Image)"
value={img3}
onChange={(e) => setImg3(e.target.value)}
/>
<input
type="text"
placeholder="Image 4 (Ex:Upload Image)"
value={img4}
onChange={(e) => setImg4(e.target.value)}
/> */}
<input
type="text"
placeholder="Category 1 (Ex:OFFBEAT)"
value={category1}
onChange={(e) => setcategory1(e.target.value)}
/>
<input
type="text"
placeholder="Category 2 (Ex:Shirt)"
value={category2}
onChange={(e) => setcategory2(e.target.value)}
/>
<input
type="text"
placeholder="Available Sizes (comma-separated) (Ex:M,L,XL,2XL)"
value={available_sizes}
onChange={(e) => setavailable_sizes(e.target.value)}
/>
<input
type="text"
placeholder="Product Code (Ex:GLK 00)"
value={productCode}
onChange={(e) => setProductCode(e.target.value)}
/>
<input
type="text"
placeholder="SKU Code (Ex:101590026003)"
value={skuCode}
onChange={(e) => setSkuCode(e.target.value)}
/>
<input
type="text"
placeholder="Pattern (Ex:Print)"
value={pattern}
onChange={(e) => setPattern(e.target.value)}
/>
<input
type="text"
placeholder="Size Chart (Ex:https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/SIZE%20GUIDE%20DIOS%20CORD.png?alt=media&token=415b4305-6e54-40c2-9b10-628aae003d19)"
value={size_chart}
onChange={(e) => setSizeChart(e.target.value)}
/>
<input
type="text"
placeholder="Fabric (Ex:Cotton)"
value={fabric}
onChange={(e) => setFabric(e.target.value)}
/>
<input
type="text"
placeholder="Color (Ex:BLACK)"
value={color}
onChange={(e) => setColor(e.target.value)}
/>
<input
type="text"
placeholder="Wash Care (Ex:Dry clean only)"
value={wash_care}
onChange={(e) => setWashCare(e.target.value)}
/>
<input
type="text"
placeholder="Model Details (Ex:Height: 6'2', Wearing Size: M)"
value={model_details}
onChange={(e) => setModelDetails(e.target.value)}
/>
{[0, 1, 2, 3].map((index) => (
    <input
        key={index}
        type="file"
        accept="image/*"
        onChange={(e) => handleImageChange(e, index)}
    />
    ))}
<button type="submit">Add Product</button>
</form>
</div>
</>
);
};

export default AdminPanel;
