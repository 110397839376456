import React, { useState, useEffect } from 'react';
import './card.css';
import { useNavigate } from 'react-router-dom';
import DDlogo from '../images/D.png'

const ProductCard = ({ product }) => {
  const [isLoading, setIsLoading] = useState(true);
  
  // const [redirectToHome, setRedirectToHome] = useState(false);  
  const navigate = useNavigate()
  const sendDits = () => {
    // return <Navigate to='/product' state={product}/>
    // setRedirectToHome(true)
    console.log(product)
    let productTitle = product.shortTitle.replace(/ /g, "-")
    navigate(`/product/${productTitle}`, { state: { product } })    
  }
  // console.log(product);
  const {
    shortTitle,
    img1,
    price,
  } = product;
  // console.log({shortTitle})

  useEffect(() => {
    const image = new Image();
    image.src = product.img1;
    image.onload = () => {
      setIsLoading(false);
    };
  }, [product.img1]);

  // Function to capitalize the first letter of a string
  // function capitalizeFirstLetter(text2) {
  //     const text = text2.toLowerCase();
  //     const maintext = text.charAt(0).toUpperCase() + text.slice(1)
  //     return maintext;
  // }  
    
  // const {
  //       shortTitle,
  //       img1,
  //       price,
  //     } = product;
 
  // const capitalShortTitle = capitalizeFirstLetter(shortTitle);
  
  

  return (
    <div className="product-card">
      {/* Loading skeleton preloader */}
      {isLoading ? (
        <div className="skeleton-container">
          <div className="skeleton-image">
            <img className='logoo' src={DDlogo} alt="logo"/>
          </div>
          <div className="skeleton-details">
            <div className="skeleton-title"></div>
            <div className="skeleton-price"></div>
          </div>
        </div>
      ) : (
        <div onClick={sendDits}>
          <div className="product-image-container">
            <img className="product-image1" src={img1} alt="something you won't get" />
          </div>

          <div className="product-details">
            <div className="price-title">
              <div className="title-div">
                <p className="product-shortTitle">{shortTitle}</p>
              </div>
              <div className="price-div">
                <p className="product-price">&#8377;{price}</p>
              </div>
            </div>

            {/* <button className="shop-now-button">Add To Cart</button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;




