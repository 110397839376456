import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth } from 'firebase/auth';
import './userDeets.css'
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';


export default function UserDeets() {

  const firebaseConfig = {
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const navigate = useNavigate();

function handleEditForm(){
  navigate('/userEditForm',{state: formData});
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const uid = GetUserID();
const [imgUrl, setImgUrl] = useState(null)
const [email, setEmail] = useState(null)
  function GetUserID() {
    const [userID, setUID] = useState(null);
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
              setImgUrl(user.photoURL)
              setEmail(user.email)
            }
        });
    }, []);
}
  const [userPhoto, setUserPhoto] = useState(null);
  const [userID, setUID] = useState(null);
  // const [user, setUser] = useState(null);
  const user = GetUserID()


const [formData, setFormData] = useState({});

  // const userEmail = user.email;
  console.log(user)
  // const userPhoto = user.photoURL;
  useEffect(() => {
    // Fetch the data from Firebase
    const firestore = firebase.firestore();

    firestore.collection('orderDetails')
      .where('email', '==', email) // Filter based on the user's email
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          // Assuming there is only one document that matches the email
          const data = querySnapshot.docs[0].data();
          setFormData(data);
        //   console.log(formData);
        } else {
          console.log('No data found for the user.');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [email]);

  return (
    <div className="main">

      <div className="user-details">
        
        <div className="container1">
          <div className="img-name">
            <img src={imgUrl} className='user-pic' alt="profile pic"/>
            <p className="Name">{formData.name}</p>
          </div>
        </div>
        <div className="container2">
          <p className="user-detail-field"><strong>Email:</strong> {formData.email}</p>
          <p className="user-detail-field"><strong>Phone Number:</strong> {formData.phoneNumber}</p>
          <p className="user-detail-field"><strong>Country:</strong> {formData.country}</p>
          <p className="user-detail-field"><strong>Apartment Number:</strong> {formData.apartmentNumber}</p>
          <p className="user-detail-field"><strong>Address:</strong> {formData.address}</p>
          <p className="user-detail-field"><strong>City:</strong> {formData.city}</p>
          <p className="user-detail-field"><strong>State:</strong> {formData.state}</p>
          <p className="user-detail-field"><strong>Pincode:</strong> {formData.pincode}</p>
          <div className="btn-div">
            <button className='edit-btn' onClick={handleEditForm}>Edit Form</button>
          </div>
        </div>

      </div>
  </div>
  );
};


