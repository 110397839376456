import React, { useState } from "react";
import Modal from "react-modal";
import "./updateStatus.css";
import Checkbox from "@mui/material/Checkbox";

Modal.setAppElement("#root");

const UpdateStatusPopup = ({ isOpen, onClose, onUpdate }) => {
  const [status, setStatus] = useState(false);

  const handleUpdate = () => {
    onUpdate(status);
    console.log(status);
    onClose();
  };

  return (
    <div className="update_status">
      <Modal className="update_status" isOpen={isOpen} onRequestClose={onClose}>
        <h2 className="h2-updatestatus"> Update Status</h2>
        <div className="checkbox-label21">
          <div>
            <div class="checkbox-wrapper-43">
              <input
                type="checkbox"
                id="cbx-43"
                checked={status}
                onChange={() => setStatus(!status)}
              />
              <label for="cbx-43" class="check">
                <svg width="18px" height="18px" viewBox="0 0 18 18">
                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                  <polyline points="1 9 7 14 15 4"></polyline>
                </svg>
              </label>
            </div>
          </div>
          <p className="update_status">
            {status ? (
              <p
                style={{
                  fontSize: "1.5rem",
                  color: "#82480Cc9",
                  borderBottom: "3px dotted #82480Cc9",
                }}
              >
                Delivered
              </p>
            ) : (
              "Not Delivered"
            )}
          </p>
          {/* <label className="checkbox-label">
          <input
            type="checkbox"
            checked={status}
            onChange={() => setStatus(!status)}
            className="checkbox-input"
            />
            </label> */}
        </div>
        <button className="update-status" onClick={handleUpdate}>
          Update
        </button>
        <button className="cancel-status" onClick={onClose}>
          Cancel
        </button>
      </Modal>
    </div>
  );
};

export default UpdateStatusPopup;
