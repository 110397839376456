import React from 'react';
import styled from 'styled-components';
import { menuItems } from "../dropdown/menuItems";
import MenuItems from "./MenuItems";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin-top:0;
  li {
    padding: 3px 8px;
  }
  .hiding-navbar-ele{
    display:none;
  }
  @media (max-width: 900px) {

    flex-flow: column nowrap;
    background-color: #f8f3e3;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    top: 0;
    left: 0;
    height: 100vh;
    width: 45%;
    padding-top: 3.5rem;
    transition: transform 0.5s ease-in-out;
    border:  1px #000 solid;


    .hiding-navbar-ele{
      margin-top:1rem;
      
      display:flex;
      justify-content:center;
      align-items:center;
    }
    .Login-user2-sidebar{
      
    }
    .cart-navbar-sidebar{
      width: 1.5rem;
  }
  .Cart-user-sidebar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position:relative;
    cursor: pointer;
    border-bottom: 2px solid #54360969;
}
  .Mycart-sidebar{
    font-family: "Playfair display",sans-serif;
    font-weight:600;
    font-size:1rem;
    color:#543609;
  }
  .Mycart-sidebar-a{
  text-decoration:none;

}
.no-of-products-sidebar{
  cursor: default;
  position: absolute;
  top:0;
  right: 0;
  padding: 0px 2px;
  border-radius: 1rem;
  background-color: #543609;
  color: #f8f3e3;
  font-size:0.7rem;

}
.link-login-sidebar{
  font-family:'Playfair Display', sans-serif;
  font-weight:600;
  text-decoration:none;
  color:#f8f3e3;
  padding: 1px 1rem;
  font-size:1rem;
  margin-top:1rem;
  border:2px solid #543609;
  border-radius:6px;
  background-color: #543609;
  text-align:center;
}
.logout-navbar-sidebar{
  font-family:'Playfair Display', sans-serif;
  font-weight:600;
  text-decoration:none;
  color:#f8f3e3;
  padding: 1px 1rem;
  font-size:1rem;
  margin-top:1rem;
  border:2px solid #543609;
  border-radius:6px;
  background-color: #543609;
  text-align:center;
}
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul 
    open={open}className="menus">
      
        {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
                <MenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                />
            );
            })}
    </Ul>
  )
}

export default RightNav


