import React, { useState } from 'react';
import './featuring.css';
// import { Link } from 'react-router-dom';

const ParentContainer = () => {
const [activeChild, setActiveChild] = useState(1);

const toggleChild = (child) => {
  setActiveChild(child === activeChild ? null : child);
  console.log(`Child ${child} clicked`);
};

return (
  <div className="parent-container-featuring">
    <div className="now-featuring">
      <h1>Now Featuring</h1>
    </div>
    <div className="child-wrapper">
      <h4 className={activeChild === 1 ? 'active2' : ''} onClick={() => toggleChild(1)}>
        Classic
      </h4>
      <h4 className={activeChild === 2 ? 'active2' : ''} onClick={() => toggleChild(2)}>
        Offbeat
      </h4>
    </div>

    <div className="scroll-container">
      {activeChild === 1 && (
        <div className="child-container">
          <a className="image-link" href="/shop?pattern=Checks&fabric=&size=&category=&color=">
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image2.jpg?alt=media&token=b9a45467-ce10-421c-ba24-5f8fbcb88d0b' alt="Band Collar" /></a>
            <a className="image-link" href='/shop?category=Shirt&color=&pattern=&fabric=Linen&size='>
            <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image1.jpg?alt=media&token=314171ff-0bc7-460d-8f3b-e4138d3d7157' alt="Checks" />
            </a>
            <a className="image-link" href='/shop?category=Short+Kurta&color=&pattern=&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image3.jpg?alt=media&token=b9385384-a1f9-4d7a-88ce-3dda026e9f0f' alt="Short Kurta" />
            </a>
            <a className="image-link" href='/shop?category=Shirt&color=&pattern=&fabric=Linen&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image4.jpg?alt=media&token=1b7fb39b-ce30-457a-8f7a-ca997aae4ff4' alt="Button Down" />
            
            </a>
            <a className="image-link" href='/shop?category=Shirt&color=&pattern=&fabric=Cotton&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image5.jpg?alt=media&token=819aa995-6d4e-4a63-b75e-f5c2544bf4a4' alt="Over shirt" />
            </a>
            <a className="image-link" href='/shop?category=Shirt&color=&pattern=&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image6.jpg?alt=media&token=3b617e41-914b-4762-9713-e3c6586b37bf' alt="Casual" />
            </a>
            
        </div>
      )}

      {activeChild === 2 && (
        <div className="child-container">
          <a className="image-link" href='/shop?category=Coord+Set&color=&pattern=&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image7.jpg?alt=media&token=2789c112-4e26-45f3-b237-5caf300265a7' alt="Long Co-ord" />
            </a>
          <a className="image-link" href='/shop?category=Shirt&color=&pattern=Print&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image8.jpg?alt=media&token=722174f5-7cd7-48a5-b42a-5a522f7ea6df' alt="Print shirt" />
            </a>
          <a className="image-link" href='/shop?category=Short+Kurta&color=&pattern=Print&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image9.jpg?alt=media&token=7c8e11ab-de84-48e5-8cf0-45ffc00eba10' alt="Print short kurta" />
            </a>
          <a className="image-link" href='/shop?category=Coord+Set&color=&pattern=&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image10.jpg?alt=media&token=6e8b36a7-af9c-40f0-8d37-6dca17e01d51' alt="Short Co-ord" />
            </a>
          <a className="image-link" href='/shop?category=Long+Kurta&color=&pattern=&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image11.jpg?alt=media&token=37c1973f-a3b1-4b20-b116-6e56075cf447' alt="Long Kurta" />
            </a>
          <a className="image-link" href='/shop?category=&color=&pattern=2+Pocket&fabric=&size='>
          <img className='child-container-img' src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/image12.jpg?alt=media&token=ba5fe7bd-a672-45a3-b0fc-e7642ba57305' alt="Two pocket Shirt" />
            </a>
        </div>
      )}
      {/* <div className='swipeFeaturing'>
    <h2>Swipe</h2><img src={rightArrow} alt='' style={{width:'3rem'}}/>
    </div> */}
    </div>
  </div>
);
};

export default ParentContainer;
