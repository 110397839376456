/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import './product.css'
import ProductDetails from "../Components/productDetails";
import ReactImageMagnify from 'react-image-magnify';
import cloth from '../Components/images/kapda.png'
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import phone from '../Components/images/phone.png';
import whatsapp from '../Components/images/whatsapp.png';
import mail from '../Components/images/mail.png'
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Import the Firestore module
// import { auth } from '../firebase';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import flag from '../Components/images/flag.png'
import needleAndThread from '../Components/images/needle-and-thread.png'
import feathers from '../Components/images/feathers.png'

import { collection, query, where, getDocs } from 'firebase/firestore';


export default function Product() {
  const [isVisible, setIsVisible] = useState(false);
  // const [product, setProduct] = useState({}); // Store the product data here
  // const location = useLocation();!=============================================
  // const product = location.state.product;!=====================================
  const [textObject, setTextObject] = useState({});
  const firebaseConfig = {
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

// =======================================Test==========================================================
const [product, setProduct] = useState({});
const { productTitle } = useParams();

const fetchProductDetails = async (smallTitle) => {
  try {
    if (smallTitle) {
      const db = firebase.firestore();
      const q = query(collection(db, 'productCode'), where('small_title', '==', smallTitle));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          // Do something with the data, such as storing it in state or displaying it
 
          setProduct(data)
        });
      } else {
        console.log('No matching product found.');
      }
    } else {
      console.log('Product title is empty.');
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};
useEffect(()=>{

  fetchProductDetails(productTitle.replace(/-/g, " "));
}, [productTitle])
























// =======================================Test==========================================================
  

const temp = product.product_details

  useEffect(() => {
    // Your Firebase initialization code here, if not already initialized
 // Your firebaseConfig object
    
    const fetchData = async (temp) => {
      const text = "Fabric - Cotton Rayon . Weight - Lightweight Fabric , Stitch - Two Thread Chain Stitch , Country Of Origin - India"
    
      // Split the text based on commas and full stops
      const dividedText = text.split(/[,.]/).map((item) => item.trim());

      // Create an object to store the key-value pairs
      const tempTextObject = {};

      // Loop through the dividedText array and extract keys and values
      dividedText.forEach((item) => {
        const parts = item.split(" - ");
        if (parts.length === 2) {
          const key = parts[0].trim();
          const value = parts[1].trim();
          tempTextObject[key] = value;
        }
      });

      // Here you can set the state or do anything with the textObject
      setTextObject(tempTextObject)
    };

    // Call the fetchData function
    fetchData(temp);
  }, [temp]);
  
  
  

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const images = [];
  for (let i = 1; i <= 4; i++) {
    const imgSrc = product[`img${i}`];
    if (imgSrc) {
      images.push({
        id: i,
        smallImage: {
          alt: `Small Image ${i}`,
          src: imgSrc,
          width: 300,
          height: 450,
        },
        largeImage: {
          src: imgSrc,
          width: 1500,
          height: 2000,
        },
      });
    }
  }

  return (
<div className="main-container-product">


<div className="product-product">
    <div className="productImages-product">
        {images.map((image) => (
            <ReactImageMagnify
            className='childProductImage-product'
            key={image.id}
            {...{
                smallImage: image.smallImage,
                largeImage: image.largeImage,
            }}
            enlargedImageContainerClassName="enlargedImage-product"
            />
        ))}                    
    </div>
    
<Swiper spaceBetween={3}
className="mobileSwiper-product"  
slidesPerView={1}
pagination={{ clickable: true }}>
      <SwiperSlide><img src={product.img1} alt="" className='mobileChildProductImage-product'/></SwiperSlide>
      <SwiperSlide><img src={product.img2} alt="" className='mobileChildProductImage-product'/></SwiperSlide>
      <SwiperSlide><img src={product.img3} alt="" className='mobileChildProductImage-product'/></SwiperSlide>
      <SwiperSlide><img src={product.img4} alt="" className='mobileChildProductImage-product'/></SwiperSlide>
    </Swiper>
    <div className="productDetails-product">
            {/* Check if 'product' data is available */}
            {Object.keys(product).length > 0 ? (
              // Render the ProductDetails component and pass 'product' as a prop
              <ProductDetails temp={product} />
            ) : (
              // Render a loading or placeholder component while waiting for 'product' data
              <p>LOADING</p>
            )}
    </div>
</div>



<div className="outerDescriptionAndPolicy-product">            
<div className="descriptionAndPolicy-product">
 <div className="row-container-product">
    
 
    <div className="box-product">
            <h6 className="descHeader-product">DESCRIPTION</h6>
            <p className="descContent-product">{product.desc}</p>
    </div>


    <div className="box-product">
      <div className="descBox-product">
        <div>
          <img src={needleAndThread} alt="Icon" style={{ width: '45px', marginRight:"10px" }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="info-product">100% {textObject["Fabric"]}</span>
          <span className="info-product">Material</span>
        </div>
      </div>

      <div className="descBox-product">
        <div>
          <img src={flag} alt="Icon" style={{ width: '45px', marginRight:"10px" }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="info-product">INDIA</span>
          <span className="info-product">Country of origin</span>
        </div>
      </div>

      <div className="descBox-product">
        <div>
          <img src={feathers} alt="Icon" style={{ width: '45px', marginRight:"10px" }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="info-product">{textObject["Weight"]}</span>
          <span className="info-product">Weight</span>
        </div>
      </div>
    </div>

    
    <div className="box-product" style={{paddingTop:"0px", marginTop:"auto"}}>
    <h6 className="descHeader-product" style={{marginBottom:"10px", marginTop:'auto'}}>Need A Custom Fit?</h6>
    <a href="https://wa.me/919324283876"><span className="descContent-product" style={{fontSize:"14px"}}>Get In Touch</span></a>  
    <h6 className="descHeader-product" style={{marginBottom:"10px", marginTop:"70px"}}>WASH CARE</h6>
        <p className="descContent-product">{product.wash_care}</p>
    </div>
 </div>
 <div className="column-container-product">
    <h6 className="descHeader-product" style={{cursor: 'pointer',marginTop:"10px", marginLeft:"2rem"}} onClick={handleClick}><FontAwesomeIcon icon={faPlus} /> RETURNS & EXCHANGES</h6>
    <div className={`content-product ${isVisible ? 'visible-product' : ''}`}>
      <h6 className="descHeader-product">REFUNDS AND RETURNS:</h6>
      <ol style={{width:"80%", marginLeft:"3rem"}}>
        <li className="listItem-product" style={{marginLeft:'-20px', marginBottom:"10px"}}>We follow the highest quality control standards and ensure 
        that each product is made with the care and workmanship that 
        our customers expect.</li>
        <li className="listItem-product" style={{marginLeft:'-20px' , marginBottom:"10px"}}>We reserve the right to cancel an order for which payment has 
        already been received. This may occur if stock is insufficient 
        or the quality of goods ordered does not meet our standards.</li>  
        <li className="listItem-product" style={{marginLeft:'-20px' , marginBottom:"10px"}}>Should we exercise this right, the customer will receive a full 
        refund via the original payment method.</li>
        <li className="listItem-product" style={{marginLeft:'-20px' , marginBottom:"10px"}}>If you're unhappy with your purchase, we will find a solution 
        for you. Dios products can be exchanged within 14 days for 
        another size or product of the same or higher value. </li>
        <li className="listItem-product" style={{marginLeft:'-20px' , marginBottom:"10px"}}>Dios products must be returned in good condition, with the 
        abels intact. Please keep in mind that you are responsible for 
        the safe delivery back to Dios of all returns, we suggest 
        courier and tracking.</li>
        <li className="listItem-product" style={{marginLeft:'-20px', marginBottom:"10px"}}> We will help you with our logistic 
        service for the same. Products once exchanged, will no longer 
        be returnable. </li>
        <li className="listItem-product" style={{marginLeft:'-20px', marginBottom:"10px"}}> Please note, discounted items or custom orders with alterations 
        cannot be returned or exchanged. </li>
        <li className="listItem-product" style={{marginLeft:'-20px', marginBottom:"10px"}}> 
        At Dios, we do our best to provide the finest quality products. 
        Like most natural textiles, our fabrics are not guaranteed 
        against natural wear, abrasion, stretch, shrinkage, fading, or 
        dye-lot variations.
        </li>
        <li className="listItem-product" style={{marginLeft:'-20px', marginBottom:"10px"}}>If and when you decide to return any of our articles, you will receive a full refund of the amount directly from us. However, please allow us 5-7 working days for the same. 
        </li>
      </ol>

      <h6 className="descHeader-product">REVIEWS:</h6>
      <p className="listItem-product Bullet-FC">&#10209; Any complaints or compliments regarding the standard and quality of the products bought by consumers should be directed to https://diosdamroos.com/review

      <br/><span className='Bullet-FC'>&#10209;</span>If you are not satisfied with the quality of our articles after use, please contact us and you will be taken care of as always. </p>
      
      <h6 className="descHeader-product">INTERNATIONAL ORDERS</h6>
      <p className='Content-FC listItem-product' style={{marginRight:'10px'}}><span className='Bullet-FC'>&#10209;</span>
For international delivery, please get in touch with us at <a class="Highlight-FC" href="/FAQs" onClick={whatsapp}>+91 93242 83876 </a> or <a
        className='Highlight-FC'
        href='https://mail.google.com/mail/u/0/#inbox'
        target='_blank'
        rel='noreferrer'
>
diosdamroos1988@gmail.com
</a>. 
</p>
    </div>
    
 </div>
</div>   
</div>       
<div className="outerNeedHelp-product">
    <p className="needHelp-product">Need help?</p>
    <div className="contactUs-product">
      <div className="contactUs-container-product">
        
          <img src={phone} alt="Icon" style={{ width: '35px', alignSelf:'center' }} />
        
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          <span className="contactUsHeaderTxt-product" style={{color:'#82480C'}}>+91 932 428 38 76</span>
          <span className="contactUsContentTxt-product" style={{color:'#82480C'}}>(Mon - Fri, 10:00-6:30 IST)</span>
        </div>
      </div>
      <div className="contactUs-container-product">
            
          <img src={whatsapp} alt="Icon" style={{ width: '35px', alignSelf:'center' }} />
        
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            <span className="contactUsHeaderTxt-product" style={{color:'#82480C'}}>+91 932 428 38 76</span>
            <span className="contactUsContentTxt-product" style={{color:'#82480C'}}>(Mon - Fri, 10:00-6:30 IST)</span>
          </div>
      </div>
      <div className="contactUs-container-product">
            
          <img src={mail} alt="Icon" style={{ width: '35px', alignSelf:'center' }} />
        
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            <span className="contactUsHeaderTxt-product" style={{color:'#82480C'}}>Diosdamroos1988@gmail.com</span>
            <span className="contactUsContentTxt-product" style={{color:'#82480C'}}>(Typical response time: 24 hours)</span>
          </div>
      </div>
    </div>
</div>



    </div>

    );
}