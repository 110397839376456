import React from "react";
import "./FooterComps.css";

const Terms = () => {
  return (
    <div className="Main-FC">
      <p
        style={{
          color: "#82480C",
          paddingLeft: "0rem",
          fontSize: "0.9rem",
          fontFamily: "'IBM Plex Mono', monospace",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none", color: "#82480C" }}>
          Home
        </a>
        <span style={{ cursor: "default", color: "#82480C" }}>
          {"/Terms And Conditions"}{" "}
        </span>
      </p>
      <h1 className="Header-FC">TERMS AND CONDITIONS:</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Thank you for visiting diosdamroos.com. Dios Damroo's provides this site
        as a service to its customers. Please review the following basic rules
        that govern your use of our site. Please note that your use of our site
        constitutes your agreement to follow and be bound by these terms. If you
        do not agree to these terms, please do not use this site.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        Since Dios Damroo's may revise this agreement at any time, you should
        visit this page periodically to review the terms and conditions of your
        use. Should you have any questions concerning any of our policies,
        please <span className="Highlight-FC">contact us</span>.
      </p>

      <h1 className="SubHeader-FC">Legal Information</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        This website is expressly owned and operated by Dios Damroo's.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        The mailing address for Dios Damroo's is 6A1, Sindhi Colony, Opposite DS
        High School, Sion West, Mumbai. Unless otherwise noted, all design and
        content featured on{" "}
        <a className="Highlight-FC" href="/t&c">
          diosdamroos.com
        </a>
        -including navigational buttons, images, artwork, graphics, photography,
        text, and the like are copyrights, trademarks, trade dress, and/or
        intellectual property that are owned, controlled, or licensed by
        DiosDamroos.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        This website in its entirety is protected by copyright and applicable
        trade dress. All worldwide rights, titles, and interests are reserved.
        Any use of our website and its content for purposes other than personal
        and noncommercial is prohibited without prior written permission from
        Dios Damroos. Do not reproduce, publish, display, modify, sell, or
        distribute any of the materials from Dios Damroo's. You may, however,
        download or electronically copy and print any of the page contents
        displayed on the site. Should you choose to download, copy, or forward
        any site materials via email, no right, title, or interest in those
        materials will be transferred to you.
      </p>

      <h1 className="SubHeader-FC">Site Transactions</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We reserve the right to refuse any order you place with us. We may, in
        our sole discretion, limit or cancel quantities purchased. These
        restrictions may include orders placed by or under the same customer
        account, the same credit card, and/or orders that use the same billing
        and/or shipping address.
        <br />
        <span className="Bullet-FC">&#10209;</span>In the event we make a change
        to or cancel an order, we will attempt to notify you by contacting the
        e-mail and/or billing address/phone number provided at the time the
        order was made.
      </p>

      <h1 className="SubHeader-FC">Limitation of Liability</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Given the unpredictability of technology and the online environment,
        Dios Damroo's does not warrant that the function or operation of this
        website will be uninterrupted or error-free, that defects will be
        corrected, or that this site or the server that makes it available will
        be free of viruses or other harmful elements.
        <br />
        <span className="Bullet-FC">&#10209;</span>As a visitor to and user of
        this website, you must assume full responsibility for any costs
        associated with the servicing of equipment used in connection with the
        use of our website. As a visitor to and a user of this website, you, in
        effect, agree that your access will be subject to the terms and
        conditions outlined in this legal notice and that access is undertaken
        at your own risk.
        <br />
        <span className="Bullet-FC">&#10209;</span>Dios Damroo's shall not be
        liable for damages of any kind related to your use of or inability to
        access this website.
      </p>

      <h1 className="SubHeader-FC">Inaccuracies</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We endeavor to present the most recent, most accurate, and most reliable
        information on our website at all times. However, there may be occasions
        when some of the information featured on{" "}
        <a className="Highlight-FC" href="/t&c">
          diosdamroos.com
        </a>{" "}
        may contain incomplete data, typographical errors, or inaccuracies.
        <br />
        <span className="Bullet-FC">&#10209;</span>Any errors are wholly
        unintentional and we apologize if erroneous information is reflected in
        the merchandise price, item availability, or any other categories which
        in any way might affect your order. Please be aware that we present our
        content 'as is' and make no claims to its accuracy, either expressed or
        implied. We reserve the right to amend errors or to update product
        information at any time without prior notice.
        <br />
        <span className="Bullet-FC">&#10209;</span>In the event a Dios article
        is listed at an incorrect price due to a photographical error or
        typographical error, Dios Damroo's shall have the right to refuse or
        cancel any orders placed for an article listed at the incorrect price.
        Dios Damroo's shall have the right to refuse or cancel any such orders
        whether or not the order has been confirmed and your credit card
        charged.
        <br />
        <span className="Bullet-FC">&#10209;</span>If your credit card has
        already been charged for the purchase and your order is canceled, Dios
        Damroo's shall issue a credit to your credit card account in the amount
        of the incorrect price.
      </p>

      <h1 className="SubHeader-FC">Availability</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        At{" "}
        <a className="Highlight-FC" href="/t&c">
          diosdamroos.com
        </a>
        , we go out of our way to select the kind of distinctive merchandise for
        which our brand is recognized.
        <br />
        <span className="Bullet-FC">&#10209;</span>When an item featured on{" "}
        <a className="Highlight-FC" href="/t&c">
          diosdamroos.com
        </a>{" "}
        is no longer in stock, we make every attempt to remove that item from
        the website promptly. Should you have any questions concerning the
        availability of a particular item, please contact us at{" "}
        <a
          className="Highlight-FC"
          href="https://mail.google.com/mail/u/0/#inbox"
          target="_blank"
          rel="noreferrer"
        >
          diosdamroos1988@gmail.com
        </a>
        .
      </p>

      <h1 className="SubHeader-FC">Colors</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We have made every effort to display as accurately as possible the
        colors of our products that appear on the website. However, due to
        monitor discrepancies, we cannot guarantee that your display of color
        will be accurate.
      </p>

      <h1 className="SubHeader-FC">Correspondence</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Although we will make every effort to respond quickly to applicable
        email messages,{" "}
        <a className="Highlight-FC" href="/t&c">
          diosdamroos.com
        </a>{" "}
        is under no obligation to respond to all pieces of correspondence
        received through this site, to maintain your submitted comments in
        confidence, or to pay compensation of any kind for your comments or
        submissions. While we welcome your comments and feedback regarding{" "}
        <a className="Highlight-FC" href="/t&c">
          diosdamroos.com
        </a>
        , our merchandise, and our services, we do not wish to receive any
        confidential or proprietary ideas, suggestions, materials, or
        information via this website or any email connection.
        <br />
        <span className="Bullet-FC">&#10209;</span>Please note that all of your
        comments, feedback, ideas, suggestions, and other submissions that are
        disclosed or submitted to our company through{" "}
        <a className="Highlight-FC" href="/t&c">
          diosdamroos.com
        </a>{" "}
        shall become and remain the property of Dios Damroo's. Any such
        disclosure or submission by you is a declaration of the full release of
        all proprietary claims and/or intellectual rights regarding your
        submission.
        <br />
        <span className="Bullet-FC">&#10209;</span>However, we will not use your
        name in connection with any such materials, information, suggestions,
        ideas, or comments unless we first obtain your permission or otherwise
        are required by law to do so.
      </p>
      <h1 className="SubHeader-FC">Indemnification</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        You agree to indemnify, defend, and hold harmless Dios Damroo's, its
        officers, directors, employees, agents, licensors, and suppliers
        (collectively the "Service Providers") from and against all losses,
        expenses, damages, and costs, including reasonable attorneys' fees,
        resulting from any violation of these terms and conditions or any
        activity related to your account (including negligent or wrongful
        conduct) by you or any other person accessing the site using your
        Internet account.
      </p>
    </div>
  );
};

export default Terms;
