import React from "react";
import Navbar from "./Components/navbar";
import Home from "./pages/Home";
// import Banner from './Body/Banner';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PhilosophyPage from "./Components/philosophy";
import RetailStoryPage from "./Components/flagshipstore";
import AllProducts from "./Components/allProducts/all-products";
import Product from "./pages/product";
import LoginForm from "./pages/login";
import Cart from "./pages/cart";
import Terms from "./pages/FooterComps/Terms";
import DeliveryAndExchange from "./pages/FooterComps/DeliveryAndExchange";
import FAQs from "./pages/FooterComps/FAQs";
import Returnsandrefunds from "./pages/FooterComps/Returnsandrefunds";
import FreeShipping from "./pages/FooterComps/FreeShipping";
import Policies from "./pages/FooterComps/Policies";
import AboutUs from "./pages/FooterComps/AboutUs";
import FooterComponent from "./Body/FooterComponent";
import ScrollToTop from "./scrolltoTop";
import CountDown from "./pages/countdown";
import Success from "./pages/success";
import UserForm from "./pages/userForm";
import UserDashboard from "./pages/userDashboard";
import Support from "./pages/support";
import EditForm from "./pages/userEditForm";
import AdminPanel from "./adminSrc/pages/adminPanel";
import { Users } from "./adminSrc/pages/users";
import Activities from "./adminSrc/pages/activities";
import { Logs } from "./adminSrc/pages/logs";
import { NewOrders } from "./adminSrc/pages/newOrders";
import { DeliveryStatus } from "./adminSrc/pages/deliveryStatus";
import Review from "./pages/review";
import Formcc from "./pages/formcc";
import LoginTest from "./pages/loginTest";
import Successcod from "./pages/successcod";
import { CodOrders } from "./adminSrc/pages/CodOrders";

const MainRouter = () => {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <header>
          <Navbar />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/count" element={<CountDown />} />
          <Route path="/philosophy" element={<PhilosophyPage />} />
          <Route path="/flagshipStore" element={<RetailStoryPage />} />
          <Route path="/product/:productTitle" element={<Product />} />
          <Route path="/shop" element={<AllProducts />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/t&c" element={<Terms />} />
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/returns&Refunds" element={<Returnsandrefunds />} />
          <Route path="/delivery&Exchange" element={<DeliveryAndExchange />} />
          <Route path="/freeShipping" element={<FreeShipping />} />
          <Route path="/privacyPolicy" element={<Policies />} />
          <Route path="/userForm" element={<UserForm />} />
          <Route path="/userDashboard" element={<UserDashboard />} />
          <Route path="/success/:id" element={<Success />} />
          <Route path="/successcod/:id" element={<Successcod />} />
          <Route path="/support" element={<Support />} />
          <Route path="/userEditForm" element={<EditForm />} />
          <Route path="/addProduct" element={<AdminPanel />} />
          <Route path="/usersDetails" element={<Users />} />
          <Route path="/activities" element={<Activities />} />
          <Route
            path="/OrderLogs-fU9H4N4AvaZw3mmZy0IePDu4x222"
            element={<Logs />}
          />
          <Route path="/newOrders" element={<NewOrders />} />
          <Route path="/codOrders" element={<CodOrders />} />
          <Route path="/deliveryStatus" element={<DeliveryStatus />} />
          <Route path="/review" element={<Review />} />
          <Route path="/testForm" element={<Formcc />} />
        </Routes>
      </Router>
      <footer>
        <FooterComponent />
      </footer>
    </div>
  );
};

export default MainRouter;
