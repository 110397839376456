import React, { useState } from 'react';
import { styled } from 'styled-components';
import RightNav from './Rightnav';

const StyledBurger = styled.div`
    width: 2rem;
    height: 1.8rem;
    position: absolute;
    top: 2rem;
    left: 15px;
    z-index: 20;
    cursor: pointer;
    display : none;

    @media only screen and (max-width: 900px){
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;    
    }
    div {
    width: 1.8rem;
    height: 1.5px;
    background-color: ${({ open }) => (open ? '#ccc' : '#82480C')};
    border-radius: 10px;
    transition: all 0.3s;
    transform-origin: 1px;

    &:nth-child(1) {
        transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
        transform: ${({ open }) => (open ? 'translateX(-100%)' : 'translateX(0)')};
        opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
}
`;

const Burger = () => {
    const [open, setOpen] = useState(false);

return (
    <>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>
        <RightNav open={open}/>
    </>
);
};

export default Burger;
