import React, { useState } from 'react';
import './review.css';
import { ToastContainer, toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/firestore';
import { Rating } from '@mui/material';

function Review(props) {
const [selected1, setSelected1] = useState(-1);
const [selected2, setSelected2] = useState(-1);
const [selected3, setSelected3] = useState(-1);
const [selected4, setSelected4] = useState(-1);
const [text, setText] = useState('');
const [email, setEmail] = useState('');

const handleStarClick1 = (event, newValue) => {
setSelected1(newValue);
};

const handleStarClick2 = (event, newValue) => {
setSelected2(newValue);
};

const handleStarClick3 = (event, newValue) => {
setSelected3(newValue);
};

const handleStarClick4 = (event, newValue) => {
setSelected4(newValue);
};

const handleInputChange = (event) => {
setText(event.target.value);
};

const handleInputChange2 = (event) => {
setEmail(event.target.value);
};

const db = firebase.firestore();

const handleSubmit = () => {
if (
    selected1 >= 1 &&
    selected1 <= 5 &&
    selected2 >= 1 &&
    selected2 <= 5 &&
    selected3 >= 1 &&
    selected3 <= 5 &&
    selected4 >= 1 &&
    selected4 <= 5 &&
    text &&
    email
) {
    const feedbackData = {
    Question1: selected1,
    Question2: selected2,
    Question3: selected3,
    Question4: selected4,
    comment: text,
    userEmail: email,
    timestamp: new Date(),
    };
    db.collection('feedback').add(feedbackData);
    toast.success('Feedback submitted successfully!');
} else {
    toast.error('Please enter valid details');
}
};

return (
<div className="Main">
    <div className="outerBox">
    <p className="headerText">
        Hello! Drop in your valuable feedback to help us learn and grow 🌻
    </p>

    <div
        style={{
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        }}
    >
        <input
        type="email"
        value={email}
        className="emailInput"
        onChange={handleInputChange2}
        placeholder="Enter your email"
        />
        <label className="label">
        How did you like the fabric of the article you received?
        </label>
        <Rating
        name="rating1"
        style={{marginTop:"10px"}}
        value={selected1}
        onChange={handleStarClick1}
        size="large"
        precision={1}
        />
        <label className="label">
        How did you like the finishing of the article you received?
        </label>
        <Rating
        name="rating2"
        value={selected2}
        style={{marginTop:"10px"}}
        size="large"
        onChange={handleStarClick2}
        precision={1}
        />
        <label className="label">
        How was the packaging of the article you received?
        </label>
        <Rating
        name="rating3"
        value={selected3}
        style={{marginTop:"10px"}}
        size="large"
        onChange={handleStarClick3}
        precision={1}
        />
        <label className="label">
        How was your overall shopping experience with us?
        </label>
        <Rating
        name="rating4"
        value={selected4}
        style={{marginTop:"10px"}}
        size="large"
        onChange={handleStarClick4}
        precision={1}
        />
        <label className="label">
        What is it that you would like us to change? Comment below.
        </label>
        <textarea
        value={text}
        onChange={handleInputChange}
        rows={5}
        style={{marginTop:"10px"}}
        cols={50}
        placeholder="Enter your text here..."
        className="textInput"
        />
    </div>
    <button className="chkOut" onClick={handleSubmit}>
        Submit
    </button>
    </div>
    <ToastContainer />
</div>
);
}

export default Review;
