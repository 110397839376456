import React from "react";

const Policies = () => {
  return (
    <div className="Main-FC">
      <p
        style={{
          color: "#82480C",
          paddingLeft: "0rem",
          fontSize: "0.9rem",
          fontFamily: "'IBM Plex Mono', monospace",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none", color: "#82480C" }}>
          Home{" "}
        </a>
        <span style={{ cursor: "default", color: "#82480C" }}>
          {"< PrivacyPolicies"}{" "}
        </span>
      </p>
      <h1 className="Header-FC">PRIVACY POLICY:</h1>

      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We recognize the importance of your privacy. We appreciate your trust in
        us. This policy describes how we treat user information we collect on{" "}
        <a
          className="Highlight-FC"
          href="https://mail.google.com/mail/u/0/#inbox"
          target="_blank"
          rel="noreferrer"
        >
          diosdamroos1988@gmail.com
        </a>
        .
      </p>

      <h1 className="SubHeader-FC">CONTACT INFORMATION</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Our system will collect your name, email, mobile number, street, city,
        state, Pincode, country, and IP address.
      </p>
      <h1 className="SubHeader-FC">PAYMENT AND BILLING INFORMATION</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        Our system will collect your billing name, billing address, and payment
        method when you buy a product. We NEVER collect your credit card number
        or credit card expiry date or other details pertaining to your credit
        card on our website. Credit card information will be obtained and
        processed by our online payment partner Eazypay. (Powered by ICICI
        bank.)
        <br />
        <span className="Bullet-FC">&#10209;</span>By visiting our website, you
        AGREE to our Privacy Policy. We will always protect your data, of
        course.
      </p>
      <h1 className="SubHeader-FC">INDIRECT COLLECTION OF INFORMATION</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We collect information directly from you. We collect information
        directly from you when you subscribe to our newsletter or buy a product.
        We also collect information if you post a comment on our websites or ask
        us a question through phone or email.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        We collect information from you passively. We use tracking tools like
        Google Analytics, Google Webmaster, browser cookies, and web beacons for
        collecting information about your usage of our website.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        We get information about you from third parties. For example, if you use
        an integrated social media feature on our websites. The third-party
        social media site will give us certain information about you. This could
        include your name and email address.
      </p>

      <h1 className="SubHeader-FC">USE OF YOUR PERSONAL INFORMATION</h1>
      <p className="Content-FC">
        <span className="Bullet-FC">&#10209;</span>
        We use the information to contact you: We might use the information you
        provide to contact you for confirmation of a purchase on our website or
        for other promotional purposes.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        We use the information to respond to your requests or questions. We
        might use your information to confirm your subscription or purchase. We
        use the information to improve our products and services. We might use
        your information to customize your experience with us. This could
        include displaying content based on your preferences. We use the
        information to look at site trends and customer interests. We may use
        your information to make our website and products better. We may combine
        information we get from you with information about you we get from third
        parties.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        We use the information for security purposes. We may use the information
        to protect our company, our customers, or our websites. We use the
        information for marketing purposes. We might send you information about
        special promotions or offers. We might also tell you about new features
        or products. These might be our own offers or products, or third-party
        offers or products we think you might find interesting. Or, for example,
        if you buy products from us we'll enroll you in our newsletter.
        <br />
        <span className="Bullet-FC">&#10209;</span>
        We use the information to send you transactional communications. We
        might send you emails or SMS about your account or a product purchase.
        We use information as otherwise permitted by law.{" "}
        <a
          className="Highlight-FC"
          href="https://mail.google.com/mail/u/0/#inbox"
          target="_blank"
          rel="noreferrer"
        >
          diosdamroos1988@gmail.com
        </a>{" "}
        is a property of Dios Damroo's. An established company since 1988. We
        have our two registered retail establishments in Sion, Mumbai by the
        same name. We are dedicated to serving you with good service and aspire
        to bring extraordinary clothing to your wardrobe.
      </p>
      {/* <h1 className="SubHeader-FC">
</h1>
<p className='Content-FC'><span className='Bullet-FC'>&#10209;</span>
</p> */}
    </div>
  );
};

export default Policies;
