import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  Firestore,
  deleteDoc,
  where,
  query,
  Timestamp,
} from "firebase/firestore";
// import { db } from '../firebase/firebase-config';
import { MdDelete, MdOutlineRefresh } from "react-icons/md";
import "./logs.css";
import "firebase/compat/firestore";
import "firebase/firestore";
import firebase from "firebase/compat/app";
import Navbar from "../components/navbar/navbar";

export const Logs = () => {
  const [data, setData] = useState([]);
  const firebaseConfig = {
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0",
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const db = firebase.firestore();
  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orderDetails"));
      const newData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(newData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id21) => {
    const cartRef = collection(db, `orderDetails`);
    const q = query(cartRef, where("tract_id", "==", id21));

    // Execute the query to get the matching document(s)
    getDocs(q)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          // If a document with the matching SKU code is found, delete it
          querySnapshot.forEach((doc) => {
            deleteDoc(doc.ref)
              .then(() => {
                console.log("Entry successfully deleted!");
                fetchData();
              })
              .catch((error) => {
                console.error("Error deleting entry:", error);
              });
          });
        } else {
          console.log("No document found with the given SKU code.");
        }
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  const formatTimestamp = (timestamp) => {
    if (
      !timestamp ||
      typeof timestamp.seconds !== "number" ||
      typeof timestamp.nanoseconds !== "number"
    ) {
      // Handle the case where timestamp is undefined or not in the expected format
      return "Invalid timestamp";
    }

    const formattedDate = new Timestamp(
      timestamp.seconds,
      timestamp.nanoseconds
    )
      .toDate()
      .toLocaleString();
    return formattedDate;
  };

  return (
    <div className="logs">
      <header>
        <Navbar />
      </header>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>All Orders Logs</h2>
            <MdOutlineRefresh
              size={"2rem"}
              style={{ cursor: "pointer", marginLeft: "1rem" }}
              onClick={() => {
                fetchData();
              }}
            />
          </div>

          <h2>Total Log Count: {data.length}</h2>
        </div>
        <table>
          <thead>
            <tr>
              <th>Track_Id</th>
              <th>Customer_Id</th>
              <th>Name</th>
              <th>Email-Id</th>
              <th>PhoneNumber</th>
              <th>Address</th>
              <th>Product_Code</th>
              <th>Selected Size</th>
              <th>Payment_Status</th>
              <th>Cod_Status</th>
              <th>Delivery_Status</th>
              <th>Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.tract_id}</td>
                <td>{item.id}</td>
                <td>{item.billing_name}</td>
                <td>{item.billing_email}</td>
                <td>{item.billing_tel}</td>
                <td>
                  {item.apartmentNumber},{item.billing_address},{" "}
                  {item.billing_zip}, {item.billing_city}, {item.billing_state},{" "}
                  {item.billing_country}
                </td>
                <td>
                  {item.product_code && Array.isArray(item.product_code) ? (
                    item.product_code.map((code, index) => (
                      <div key={index}>{code}</div>
                    ))
                  ) : (
                    <div>No product code available</div>
                  )}
                </td>

                <td>
                  {item.selectedSize ? (
                    item.selectedSize.map((size, index) => (
                      <div key={index}>{size}</div>
                    ))
                  ) : (
                    <div>-</div>
                  )}
                </td>
                <td>{item.payment_status ? "Paid" : "Not Paid"}</td>
                <td>{item.cod_status ? "True" : "False"}</td>
                <td>{item.delivery_status ? "Delivered" : "Not Delivered"}</td>
                <td>{formatTimestamp(item.time_stamp)}</td>
                <td>
                  <MdDelete
                    size={24}
                    className="deleteButton-Admin"
                    onClick={() => handleDelete(item.tract_id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <UpdateStatusPopup
    isOpen={isPopupOpen}
    onClose={handleClosePopup}
    onUpdate={handleUpdateStatus}
    /> */}
      </div>
    </div>
  );
};
