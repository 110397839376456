import React, { useEffect, useState } from "react";
import "./userForm.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { baseURL } from "../api";
import axios from "axios";
import Cart from "./cart";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import { SwipeableButton } from "react-swipeable-button";
import confirmOrderLogo from "../Components/images/DD-T.png";
// import {auth} from '../firebase'
const firebaseConfig = {
  // Your Firebase configuration
  apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
  authDomain: "diosdamroo-b97a0.firebaseapp.com",
  projectId: "diosdamroo-b97a0",
  storageBucket: "diosdamroo-b97a0.appspot.com",
  messagingSenderId: "28288876745",
  appId: "1:28288876745:web:b52667cf7c41499a648d18",
  measurementId: "G-DFF1V6MBR0",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// const uid = GetUserID();
//   console.log(uid)
//   function GetUserID() {
//     const [userID, setUID] = useState(null);
//     useEffect(() => {
//       auth.onAuthStateChanged(user => {
//         if (user) {
//           setUID(user.uid);
//         }
//       });
//     }, []);
//     return userID;
//   }
const firestore = firebase.firestore();

const UserForm = () => {
  const location = useLocation();
  const cartItems = location.state.cartItems;
  const [reset, setReset] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const onSuccess = () => {
    console.log("Successfully Swiped!");
  };
  const handlePopUp = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const img1Array = cartItems.map((item) => item.img1);
  const smallTitleArray = cartItems.map((item) => item.small_title);
  const perPrice = cartItems.map((item) => item.price);

  const [coupon, setCoupon] = useState("");
  const [price, setPrice] = useState();

  useEffect(() => {
    if (coupon === "Dios1988") {
      let temp = location.state.e;
      let deductedAmt = (temp * 10) / 100;
      let finalPrice = temp - deductedAmt;
      setPrice(finalPrice);
    } else {
      setPrice(location.state.e);
    }
  }, [coupon]);
  const productCodes = location.state.productCodes;
  const selectedSize = location.state.selectedSize;
  const [custID, setcustID] = useState(uuidv4());

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
  const day = currentDate.getDate();

  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  const [formData, setFormData] = React.useState({
    billing_name: "",
    billing_email: "",
    billing_tel: "",
    billing_country: "",
    // firstName: '',
    // lastName: '',
    billing_address: "",
    product_code: productCodes,
    payment_status: false,
    delivery_status: false,
    cod_status: false,
    time_stamp: currentDate,
    tract_id: custID,
    selectedSize: selectedSize,
    totalAmount: 0,
    apartmentNumber: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    // confirmation:''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(phoneNumber);
  };
  const navigate = useNavigate();

  // console.log(price);
  const handlePlaceOrder = () => {
    if (
      validateEmail(formData.billing_email) &&
      validatePhoneNumber(formData.billing_tel) &&
      formData.billing_name &&
      formData.billing_address &&
      formData.apartmentNumber &&
      formData.billing_state &&
      formData.billing_city &&
      formData.billing_zip &&
      formData.billing_country
    ) {
      // axios.post(`${baseURL}/api/payment/create-checkout-session`,{price,smallTitleArray,custID}).then(res =>{
      //   window.location.href = res.data.url;
      // }).catch(err => console.log(err))
    }
    // else{
    //   toast.error("Please enter valid details!")
    // }

    if (!formData.billing_name.trim()) {
      toast.error("Please enter your name.");
      return;
    }

    if (!validateEmail(formData.billing_email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!validatePhoneNumber(formData.billing_tel)) {
      toast.error("Please enter a valid phone number (10 digits).");
      return;
    }

    firestore
      .collection("orderDetails")
      .add(formData)
      .then(() => {
        // console.log('Data uploaded successfully!');
      })
      .catch((error) => {
        console.error("Error uploading data:", error);
      });

    setFormData({
      billing_name: "",
      billing_tel: "",
      billing_country: "",
      billing_email: "",
      tract_id: uuidv4(),
      // firstName: '',
      // lastName: '',
      product_code: [],
      selectedSize: [],
      payment_status: false,
      delivery_status: false,
      cod_status: false,
      time_stamp: Date.now(),
      billing_address: "",
      totalAmount: price,
      apartmentNumber: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
      // confirmation:'false'
    });
  };
  const handlePlaceOrderCOD = () => {
    if (
      validateEmail(formData.billing_email) &&
      validatePhoneNumber(formData.billing_tel) &&
      formData.billing_name &&
      formData.billing_address &&
      formData.apartmentNumber &&
      formData.billing_state &&
      formData.billing_city &&
      formData.billing_zip &&
      formData.billing_country
    ) {
      // axios.post(`${baseURL}/api/payment/create-checkout-session`,{price,smallTitleArray,custID}).then(res =>{
      //   window.location.href = res.data.url;
      // }).catch(err => console.log(err))
    }
    // else{
    //   toast.error("Please enter valid details!")
    // }

    if (!formData.billing_name.trim()) {
      toast.error("Please enter your name.");
      return;
    }

    if (!validateEmail(formData.billing_email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!validatePhoneNumber(formData.billing_tel)) {
      toast.error("Please enter a valid phone number (10 digits).");
      return;
    }

    firestore
      .collection("orderDetails")
      .add(formData)
      .then(() => {
        console.log("Data uploaded successfully!");
        setTimeout(() => {
          navigate(`/successcod/${custID}`);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error uploading data:", error);
      });

    setFormData({
      billing_name: "",
      billing_tel: "",
      billing_country: "",
      billing_email: "",
      tract_id: uuidv4(),
      // firstName: '',
      // lastName: '',
      product_code: [],
      selectedSize: [],
      payment_status: false,
      delivery_status: false,
      cod_status: false,
      time_stamp: Date.now(),
      billing_address: "",
      totalAmount: price,
      apartmentNumber: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
      // confirmation:'false'
    });
  };
  const testObject = {
    merchantId: 2674019,
    orderId: 69,
    currency: "INR",
    amount: 1.0,
    redirectUrl: "http://127.0.0.1:3001/ccavResponseHandler",
    cancelUrl: "http://127.0.0.1:3001/ccavResponseHandler",
    language: "EN",
    billingName: "Peter",
    billingAddress: "Santacruz",
    billingCity: "Mumbai",
    billingState: "MH",
    billingZip: 400054,
    billingCountry: "India",
    billingTel: 9876543210,
    billingEmail: "testing@domain.com",
    deliveryName: "Sam",
    deliveryAddress: "Vile Parle",
    deliveryCity: "Mumbai",
    deliveryState: "Maharashtra",
    deliveryZip: 400038,
    deliveryCountry: "India",
    deliveryTel: "0123456789",
    merchantParam1: "additional Info.",
    merchantParam2: "additional Info.",
    merchantParam3: "additional Info.",
    merchantParam4: "additional Info.",
    merchantParam5: "additional Info.",
    promoCode: "",
    customerIdentifier: "",
  };
  function handleOnclick() {
    if (
      formData.billing_name &&
      formData.billing_email &&
      formData.billing_tel &&
      formData.billing_address &&
      formData.apartmentNumber &&
      formData.billing_state &&
      formData.billing_city &&
      formData.billing_zip &&
      formData.billing_country
    ) {
      handlePlaceOrder();
    } else {
      // toast.error("Please Enter Valid Details");
    }
  }
  function handleOnclickCOD() {
    if (
      formData.billing_name &&
      formData.billing_email &&
      formData.billing_tel &&
      formData.billing_address &&
      formData.apartmentNumber &&
      formData.billing_state &&
      formData.billing_city &&
      formData.billing_zip &&
      formData.billing_country
    ) {
      handlePlaceOrderCOD();
    } else {
      // toast.error("Please Enter Valid Details");
    }
  }
  const handleSubmit = () => {
    axios
      .post(`${baseURL}/about`, { testObject })
      .then((response) => {
        // Handle the response here
        console.log("Response:", response);
        window.location.href = response.config.url;
        // console.log(JSON.parse(response.config.data))

        // You can perform further actions based on the response, e.g., redirect or update the UI
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error:", error);

        // You can display an error message or take other appropriate actions
      });
  };
  return (
    <>
      <div className="form-container">
        <p className="heading">User Information</p>
        <div className="form-inputs">
          <form
            style={{ width: "100%" }}
            method="POST"
            action="https://diosdamroos-backend.onrender.com/ccavRequestHandler"
          >
            <input
              style={{ display: "none" }}
              type="text"
              name="merchant_id"
              id="merchant_id"
              value="2674019"
            />
            <input
              style={{ display: "none" }}
              type="text"
              name="currency"
              value="INR"
            />
            <input
              style={{ display: "none" }}
              type="text"
              name="order_id"
              value={custID}
            />
            <input
              style={{ display: "none" }}
              type="text"
              name="amount"
              value={price}
            />
            <input
              style={{ display: "none" }}
              type="text"
              name="redirect_url"
              value={`http://diosdamroos.com/success/${custID}`}
            />
            <input
              style={{ display: "none" }}
              type="text"
              name="cancel_url"
              value="http://diosdamroos.com/"
            />
            <input
              style={{ display: "none" }}
              type="text"
              name="language"
              id="language"
              value="EN"
            />

            <label>
              Name:
              <input
                required
                type="text"
                className="name-field"
                name="billing_name"
                value={formData.name}
                onChange={handleChange}
              />
            </label>
            <label>
              Email:
              <input
                required
                type="email"
                className="email-field"
                name="billing_email"
                value={formData.email}
                onChange={handleChange}
              />
            </label>
            <label>
              Phone Number:
              <input
                required
                type="tel"
                name="billing_tel"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </label>
            <h2 className="address-txt">User Details</h2>

            <div className="country-aptno">
              <label>
                Apartment Number:
                <input
                  required
                  type="text"
                  className=""
                  name="apartmentNumber"
                  value={formData.apartmentNumber}
                  onChange={handleChange}
                />
              </label>
            </div>

            <label>
              Address:
              <input
                required
                type="text"
                className="address-field"
                name="billing_address"
                value={formData.address}
                onChange={handleChange}
              />
            </label>
            <label>
              Pincode:
              <input
                required
                type="text"
                className="pincode-field"
                name="billing_zip"
                value={formData.pincode}
                onChange={handleChange}
              />
            </label>
            <label>
              City:
              <input
                required
                type="text"
                className="city-field"
                name="billing_city"
                value={formData.city}
                onChange={handleChange}
              />
            </label>
            <label>
              State:
              <input
                required
                type="text"
                className="state-field"
                name="billing_state"
                value={formData.state}
                onChange={handleChange}
              />
            </label>

            <label>
              Country:
              <input
                required
                type="text"
                className="country-field"
                name="billing_country"
                value={formData.country}
                onChange={handleChange}
              />
            </label>
            <h2 className="address-txt">Coupon Code</h2>
            <label>
              Enter Coupon Code Here:
              <input
                type="text"
                name="coupon"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
            </label>
            <p className="userform_content">Proceed with:</p>
            <div className="btn">
              <button
                type="submit"
                className="outline-button"
                onClick={handleOnclick}
              >
                Other Methods
              </button>
              {/* <button className="outline-button" onClick={handleSubmit}>
          Test Order
        </button> */}
            </div>
          </form>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="submit"
              className="outline-button"
              onClick={handlePopUp}
            >
              Cash On Delivery Method
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
      {showPopup && (
        <div className="popup_userform">
          <div className="popup-content_userform">
            <button
              className="close-button-popup-userform"
              onClick={handleClosePopup}
            >
              X
            </button>
            {/* Here you can add your order placement content */}
            {/* <button className="outline-button" onClick={handleOnclick}>
              Place Order
            </button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                src={confirmOrderLogo}
                style={{ width: "7rem" }}
                alt="confirmOrder"
              />
            </div>
            <p className=" popUp_content">Confirm your order:</p>
            <div className="">
              <SwipeableButton
                onSuccess={handleOnclickCOD} //callback function
                text="Swipe to continue" //string
                text_unlocked="Processed" //string
                color="#82480C"
              />
            </div>
          </div>
        </div>
      )}
      {/* <Cart onTotal={(data) => setTotalPrice(data)} /> */}
    </>
  );
};

export default UserForm;
