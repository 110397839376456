import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { auth } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import 'react-tabs/style/react-tabs.css';
import './userDashboard.css';
import firebase from 'firebase/compat/app';
import UserDeets from "./userDeets";

export default function UserDashboard() {
  const [orderItems, setOrderItems] = useState([]);
  const [fetchedCartItemsCurr, setFetchedCartItemsCurr] = useState([]);
  const [fetchedCartItemsPrev, setFetchedCartItemsPrev] = useState([]);

  const db = firebase.firestore();


  useEffect(() => {
    const getUserEmail = () => {
      return new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user.email);
          } else {
            reject(new Error("User not authenticated"));
          }
        });
      });
    };

    const fetchOrderItems = async () => {
      try {
        const finalEmail = await getUserEmail();
        const q = query(
          collection(db, 'orderDetails'),
          where('email', '==', finalEmail),
          where('payment_status', '==', true),
          where('delivery_status', '==', false)
        );
    
        getDocs(q)
          .then((querySnapshot) => {
            const items = querySnapshot.docs.map((doc) => doc.data());
            setOrderItems(items);
    
            const uid = auth.currentUser.uid;
    
            // Use a Set to keep track of unique product codes
            const uniqueProductCodes = new Set();
            
            const idArrays = items.map((item) => item.product_code).filter(Boolean);
            
            // Iterate through the idArrays and add unique product codes to the set
            idArrays.forEach((idArray) => {
              idArray.forEach((id) => uniqueProductCodes.add(id));
            });
    
            // Convert the set back to an array
            const uniqueProductCodesArray = Array.from(uniqueProductCodes);
    
            if (uniqueProductCodesArray.length > 0) {
              const cartItemsPromises = uniqueProductCodesArray.map((id) =>
                getDocs(collection(db, `productCode`), where('product_code', '==', id))
              );
    
              Promise.all(cartItemsPromises)
                .then((snapshots) => {
                  const combinedItems = snapshots.map((snapshot) => snapshot.docs[0].data());
                  setFetchedCartItemsCurr(combinedItems);
                  console.log('Fetched Cart Items:', combinedItems);
                })
                .catch((error) => {
                  console.error('Error fetching cart items:', error);
                });
            }
          })
          .catch((error) => {
            console.error('Error fetching order items:', error);
          });
      } catch (error) {
        console.error('Error fetching order items:', error);
      }
    };

    fetchOrderItems();
  }, []);

  useEffect(() => {
    const getUserEmail = () => {
      return new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user.email);
          } else {
            reject(new Error("User not authenticated"));
          }
        });
      });
    };

    const fetchOrderItems = async () => {
      try {
        const finalEmail = await getUserEmail();
        const q = query(
          collection(db, 'orderDetails'),
          where('email', '==', finalEmail),
          where('payment_status', '==', true),
          where('delivery_status', '==', true)
        );
    
        getDocs(q)
          .then((querySnapshot) => {
            const items = querySnapshot.docs.map((doc) => doc.data());
            setOrderItems(items);
    
            const uid = auth.currentUser.uid;
    
            // Use a Set to keep track of unique product codes
            const uniqueProductCodes = new Set();
            
            const idArrays = items.map((item) => item.product_code).filter(Boolean);
            
            // Iterate through the idArrays and add unique product codes to the set
            idArrays.forEach((idArray) => {
              idArray.forEach((id) => uniqueProductCodes.add(id));
            });
    
            // Convert the set back to an array
            const uniqueProductCodesArray = Array.from(uniqueProductCodes);
    
            if (uniqueProductCodesArray.length > 0) {
              const cartItemsPromises = uniqueProductCodesArray.map((id) =>
                getDocs(collection(db, `productCode`), where('product_code', '==', id))
              );
    
              Promise.all(cartItemsPromises)
                .then((snapshots) => {
                  const combinedItems = snapshots.map((snapshot) => snapshot.docs[0].data());
                  setFetchedCartItemsPrev(combinedItems);
                  console.log('Fetched Cart Items:', combinedItems);
                })
                .catch((error) => {
                  console.error('Error fetching cart items:', error);
                });
            }
          })
          .catch((error) => {
            console.error('Error fetching order items:', error);
          });
      } catch (error) {
        console.error('Error fetching order items:', error);
      }
    };

    fetchOrderItems();
  }, []);


  return (
    <div>
      <h1 className="header-userDashboard">User Dashboard</h1>
      <Tabs>
        <TabList className="custom-tab-list">
          <Tab className="custom-tab">User Details</Tab>
          {/* <Tab className="custom-tab">Current Orders</Tab>
          <Tab className="custom-tab">Previous Orders</Tab> */}
        </TabList>

        <TabPanel>
          <div>
            <UserDeets/>
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            {/* Current Orders Content */}
            <div style={{ backgroundColor: "#f8f3e3", marginTop:'1rem' }}>
              {fetchedCartItemsCurr.map((item) => (
                <div className="mainTable-cart" key={item.id}>
                  <div className="column-container-cart">
                    <div className="row-container-cart" >
                      <img src={item.img1} className="productImage-cart" alt="Product" />
                      <div className="innerCol-container-cart">
                      <h1 className="bigTitty-cart">{item.small_title}</h1>
                      <span className="smallTitty-cart">{item.big_title}</span>
                        <h3 className="smallTitty-cart">₹{item.price.toLocaleString()}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            {/* Previous Orders Content */}
            <div>
            {/* Current Orders Content */}
            <div style={{ backgroundColor: "#f8f3e3", marginTop:'1rem' }}>
              {fetchedCartItemsPrev.map((item) => (
                <div className="mainTable-cart" key={item.id}>
                  <div className="column-container-cart">
                    <div className="row-container-cart" >
                      <img src={item.img1} className="productImage-cart" alt="Product" />
                      <div className="innerCol-container-cart">
                        <h1 className="bigTitty-cart">{item.small_title}</h1>
                        <span className="smallTitty-cart">{item.big_title}</span>
                        <h3 className="smallTitty-cart">₹{item.price.toLocaleString()}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}