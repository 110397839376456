import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Burger from "./Burger";
import logo from "./images/logo2.png";
import "./navbar.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Link, useNavigate } from "react-router-dom";
import cart from "./images/cart.png";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getDocs, collection, getFirestore } from "firebase/firestore";
import { Icon } from "react-icons-kit";
import { app, db, auth } from "../firebase";
import { config } from "@fortawesome/fontawesome-svg-core";
import defaultProfile from "./images/user.png";
import ExitSignout from "./images/exit.png";
import { useAuth0 } from "@auth0/auth0-react";

const Nav = styled.nav`
  .menus {
    display: flex;
    list-style: none;
  }
  
  @media only screen and (max-width: 900px) {
    z-index: 999;
    margin-top: 0rem;
  }
}`;

const Navbar = ({ open, setOpen }) => {
  // const [prevScrollPos, setPrevScrollPos] = useState(0);
  // const [navbarTop, setNavbarTop] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY || document.documentElement.scrollTop;
  //     const shouldShowNavbar = currentScrollPos < prevScrollPos || currentScrollPos === 0;
  //     setPrevScrollPos(currentScrollPos);
  //     setNavbarTop(shouldShowNavbar ? -4 : 0);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [prevScrollPos]);
  // For devang==================================================
  const config = {
    apiKey: "AIzaSyB5DhuS4ygtbwQPEfkmCVgF7Y4X-pWiHoE",
    authDomain: "diosdamroo-b97a0.firebaseapp.com",
    projectId: "diosdamroo-b97a0",
    storageBucket: "diosdamroo-b97a0.appspot.com",
    messagingSenderId: "28288876745",
    appId: "1:28288876745:web:b52667cf7c41499a648d18",
    measurementId: "G-DFF1V6MBR0",
  };
  const navigate = useNavigate();

  const uid = GetUserID();
  const [userName, setUserName] = useState("");
  // console.log(uid)
  function GetUserID() {
    const [userID, setUID] = useState(null);
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUID(user.uid);
        }
      });
    }, []);
    return userID;
  }
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  let [isAdmin, setIsAdmin] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    if (isAuthenticated) {
      setIsHover(!isHover);
    } else {
      navigate("/loginTest");
    }
  };

  const [entryCount, setEntryCount] = useState(0);
  const app = firebase.initializeApp(config);
  const db = firebase.firestore();

  useEffect(() => {
    if (isAuthenticated) {
      setUserName(user.name);
      // console.log(userName);
      const unsubscribe = db
        .collection(`cart ${user.sub}`)
        .onSnapshot((querySnapshot) => {
          // Get the size/length of the querySnapshot
          const count = querySnapshot.size;
          setEntryCount(count);
        });
    } else {
      setEntryCount(0);
    }
    // Cleanup the listener when the component unmounts
  }, [db, user]);

  //   {/For Devang/}

  useEffect(() => {
    if (userName === "Dev Hive") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userName, user]);
  // initializeApp(firebaseConfig)
  const [showLogOut, setShowLogOut] = useState(false);

  const handleImageClick = () => {
    setShowLogOut((prevshowLogOut) => !prevshowLogOut);
  };
  // const auth = getAuth(app)

  const [user1, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user1) => {
      if (user1) {
        // User is signed in
        setUser(user1);
        // console.log(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleProfile = () => {
    navigate("/userDashboard");
  };
  const handleLogout = () => {
    logout({ returnTo: window.location.origin }); // Redirect to the homepage after logout
  };
  //============================================================
  return (
    <div>
      <div className="navbr-header">
        <div className="moving-text-container">
          <p className="moving-text">
            Buy our new shirt, send your old. Get flat 10% off.
          </p>
        </div>
      </div>
      <div className="navbar">
        {/* <div className="navbar" style={{ marginTop: `${navbarTop}rem` }}> */}
        <Nav>
          <Burger />
        </Nav>
        {isAdmin && (
          <p
            className="admin-text"
            style={{ cursor: "pointer", color: "#82480C" }}
            onClick={() => navigate("/OrderLogs-fU9H4N4AvaZw3mmZy0IePDu4x222")}
          >
            ADMIN
          </p>
        )}
        <div className="nav-area">
          <a href="/">
            <img className="logo" src={logo} alt="logo" />
          </a>
        </div>
        <div className="Login-user">
          <div className="Cart-user">
            <p className="no-of-products">{entryCount}</p>
            <Link to="/cart">
              <img src={cart} alt="" className="cart-navbar" />
            </Link>
          </div>
          {!isAuthenticated ? (
            <div class="login-overlay">
              <img
                onClick={() => loginWithRedirect()}
                src={defaultProfile}
                alt="userprofile"
                class="login-navbar"
              />
              <div class="overlay-text">Login</div>
            </div>
          ) : (
            <div className="after-login-navbar">
              <img
                className="profile-user"
                src={user.picture}
                alt="User"
                onClick={handleImageClick}
              />
              {showLogOut && (
                <div className="ExitSignout_Main" onClick={handleLogout}>
                  <img src={ExitSignout} className="ExitSignout" alt="" />
                  <p className="logout-navbar">LogOut</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

// <div className='Profile-icon' >
//           <div onClick={handleMouseEnter}>
//         {user1?.photoURL ? (
//         <img className='profile-user' src={user1.photoURL} alt="" />
//       ) : (

//           <img className='default-user' onClick={handleMouseEnter} src={defaultProfile} alt=""/>

//       )}</div>

//       {isHover && (
//         <div className="profile-user-details">
//           {user1?.email ? (
//             <>
//               <div className='after-login-navbar' style={{marginRight:'0.1rem'}}>
//                 {/*<button className='logout-navbar' onClick={handleProfile}>Profile</button>*/}
//                 <button className='logout-navbar' onClick={handleLogOut}>Log out</button>
//               </div>
//               {/* <p>Name: <b>{user1.displayName}</b></p> */}
//             </>
//           ) : (
//             <></>
//           )}
//         </div>
//       )}
//         </div>
