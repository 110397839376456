import React from "react";

import "./Movingbanners.css";
const Movingbanners = () => {
  return (
    <div className="moving-BC">
      <div className="Title-MB">
        <h1>Our Linen Story</h1>
      </div>
      {/* BannerContainer */}
      <div className="main-moving-img-container">
        <div className="moving-imgs">
          <a
            className="image-link21"
            href="/shop?category=Pyjama+Coord&color=&pattern=&fabric=&size="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/1.jpg?alt=media&token=da6b48e4-1e62-4c43-96ec-e00c3a8cf835"
              className="img-moving"
              alt="img1"
            />
          </a>
          <a
            className="image-link21"
            href="/shop?category=Pyjama+Coord&color=&pattern=&fabric=&size="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/2.jpg?alt=media&token=e270ee62-2159-4620-84ad-15dafade21d4"
              className="img-moving"
              alt="img2"
            />
          </a>
          <a
            className="image-link21"
            href="/shop?category=Pants&color=&pattern=&fabric=Linen&size="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/3.jpg?alt=media&token=1635a3d1-7ffe-48ef-8917-4d5831cce49a"
              className="img-moving"
              alt="img3"
            />
          </a>
          {/* <a className='image-link21' href='/shop?category=Shirt&color=&pattern=2+Pocket&fabric=&size='>
    <img src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/4.jpg?alt=media&token=3d125657-60f6-41da-88a9-a29851e4ae3bhttps://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/4.jpg?alt=media&token=3d125657-60f6-41da-88a9-a29851e4ae3b' className='img-moving' alt='img4'/>
    </a> */}
          {/* <a className='image-link21' href='/shop?category=Long+Kurta&color=&pattern=&fabric=&size='>
    <img src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/5.jpg?alt=media&token=83339958-40bc-420a-9e80-332772046f72' className='img-moving' alt='img5'/>
    </a> */}
          {/* <a className='image-link21' href='/shop?category=Shirt&color=&pattern=&fabric=Linen&size='>
    <img src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/6.jpg?alt=media&token=2bae3e7c-b566-45cd-93d4-b6157ed25586' className='img-moving' alt='img6'/>

    </a> */}
          <a
            className="image-link21"
            href="/shop?category=Pants&color=&pattern=&fabric=Linen&size="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/7.jpg?alt=media&token=fb73094a-f53d-4e83-8fd5-6f7e786c8a0f"
              className="img-moving"
              alt="img7"
            />
          </a>
          <a
            className="image-link21"
            href="/shop?category=Long+Kurta&color=&pattern=&fabric=&size="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/8.jpg?alt=media&token=dadc4450-89fa-404a-9c72-b4b56a55b6e5"
              className="img-moving"
              alt="img8"
            />
          </a>
          <a
            className="image-link21"
            href="/shop?category=Long+Kurta&color=&pattern=&fabric=&size="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/9.jpg?alt=media&token=0f278a16-2c38-42be-91ba-98312449adab"
              className="img-moving"
              alt="img9"
            />
          </a>
          <a
            className="image-link21"
            href="/shop?category=Shirt&color=&pattern=&fabric=Linen&size="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/10.jpg?alt=media&token=928f37c8-ce19-467f-b9b5-703ccee1efbc"
              className="img-moving"
              alt="img10"
            />
          </a>
          {/* <a className='image-link21' href='/shop?category=Coord+Set&color=&pattern=&fabric=&size='>
    <img src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/11.jpg?alt=media&token=cfe16957-51c6-498b-90b6-d36790d1f43c' className='img-moving' alt='img11'/>

    </a> */}
          {/* <a className='image-link21' href='shop?category=Shirt&color=&pattern=2+Pocket&fabric=Linen&size='>
    <img src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/12.jpg?alt=media&token=2afb8836-920d-4cd3-ab4a-cee0d6078caf' className='img-moving' alt='img12'/>
    </a> */}
          {/* <a className='image-link21' href='/shop?category=Long+Kurta&color=&pattern=&fabric=&size='>
    <img src='https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/13.jpg?alt=media&token=9cd61478-5d63-4d5e-a9c3-f716d7bad53e' className='img-moving' alt='img13'/>

    </a> */}
          <a
            className="image-link21"
            href="/shop?pattern=Checks&fabric=&size=&category=&color="
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/14.jpg?alt=media&token=42a07379-fb61-4007-88e0-14527bfa9469"
              className="img-moving"
              alt="img14"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Movingbanners;
