import React, { useState } from "react";
import "./Movingbanners2.css";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Movingbanners2 = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please enter your email", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "emailSubs"), { email });
      console.log("Email uploaded successfully with ID:", docRef.id);
      setEmail("");
      toast.success("You have subscribed successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error uploading email:", error);
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div className="MovingMain2">
      <div className="moving-BC2">
        {/* BannerContainer */}
        <div className="moving-imgs2">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/1TB.jpg?alt=media&token=098b0a9e-540f-425c-8456-942b865bbebe"
            className="img-moving2"
            alt="img1"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/2TB.jpg?alt=media&token=9c0de539-1be4-4f15-9b52-d915ecbab8c5"
            className="img-moving2"
            alt="img2"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/3TB.jpg?alt=media&token=e92ac5f8-127c-4e1d-b5dc-5d6c11413fa1"
            className="img-moving2"
            alt="img3"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/4TB.jpg?alt=media&token=49b42e84-f46f-4235-8671-8a3c619816e3"
            className="img-moving2"
            alt="img4"
          />
        </div>
      </div>
      <div className="Title-MB2">
        <h1>Keep me Posted!</h1>
        <p>
          Subscribe to get notified about product launches, special offers, and
          company news.
        </p>
        <div className="form-MB">
          <input
            type="text"
            className="keep-posted"
            value={email}
            onChange={handleInputChange}
            placeholder="Enter Your Email"
          />
          <button onClick={handleSubmit}>Submit</button>
        </div>
      </div>
      <div className="moving-BC2">
        {/* BannerContainer */}
        <div className="moving-imgs2">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/5TB.jpg?alt=media&token=743bc5bd-e3b1-4bb9-b55d-c094b213111d"
            className="img-moving2"
            alt="img5"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/6TB.jpg?alt=media&token=f7f58304-0be1-4972-89c0-522cf8bf69e6"
            className="img-moving2"
            alt="img6"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/7TB.jpg?alt=media&token=a82f586c-490f-441c-ac45-940c036d36ca"
            className="img-moving2"
            alt="img7"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/diosdamroo-b97a0.appspot.com/o/8TB.jpg?alt=media&token=631c6ff9-cac6-4c73-b497-a0f460dab07a"
            className="img-moving2"
            alt="img8"
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Movingbanners2;
